import React, { useState, useEffect } from "react";
import { Popconfirm, Modal, Button, Table, Form, Input, Select, message } from "antd";

import axios from "axios";
import DefaultLayout from '../components/DefaultLayout';
import CloseButton from "../components/CloseButton";


import {
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";

const TaxesSetting = () => {

  const [taxesData, setTaxesData] = useState([]);
  const [popupModal, setPopupModal] = useState(false);
  const [editTaxes, setEditTaxes] = useState(null);
  const getAllTaxes = async () => {
    try {

      const { data } = await axios.get("/api/taxes/get-taxes");

      setTaxesData(data);

      console.log(data);

    } catch (error) {
      console.log(error);
    }

  };
  useEffect(() => {

    getAllTaxes();
  }, []);

  //handle delete
  const handleDelete = async (record) => {
    try {


      await axios.post("/api/taxes/delete-taxes", { taxesId: record._id });
      message.success('Taxes Deleted Successfully')
      getAllTaxes();
      setPopupModal(false);

    } catch (error) {
      message.success('Something Went Wrong')
      console.log(error);
    }
  }

  //able data
  const columns = [

    {
      title: 'No',
      key: 'index',
      render: (text, record, recordIndex) => (
        <span>{recordIndex + 1}</span>
      ),
    },
    { title: "Tax Name", dataIndex: "name" },
    { title: "Tax Rate", dataIndex: "rate" },
    { title: "Description", dataIndex: "description" },


    {
      title: "Actions",
      dataIndex: "_id",
      render: (id, record) => (
        <div>
          <EditOutlined
            style={{
              cursor: "pointer"
            }}
            onClick={() => {
              setEditTaxes(record);
              setPopupModal(true);
            }} />
          <Popconfirm
            title="Are you sure you want to delete this record?"
            onConfirm={() => handleDelete(record)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined
              style={{ cursor: "pointer", marginLeft: 8 }}
            />
          </Popconfirm>

        </div>
      ),

    },
  ];



  //handle submit
  const handleSubmit = async (value) => {
    if (editTaxes === null) {
      try {



        const newValues = { ...value };
        const res = await axios.post("/api/taxes/add-taxes", newValues);
        message.success('Taxes Added Successfully')
        getAllTaxes();
        setPopupModal(false);
      } catch (error) {
        message.success('Something Went Wrong')
        console.log(error);
      }
    } else {
      try {
        await axios.put("/api/taxes/edit-taxes", { ...value, taxesId: editTaxes._id });
        message.success('Category Updated Successfully')
        getAllTaxes();
        setPopupModal(false);
      } catch (error) {
        message.success('Something Went Wrong')
        console.log(error);
      }
    }
  };


  return (
    <DefaultLayout>
      <div className="d-flex justify-content-between">
        <h1>Tax</h1>
        <Button type="primary" onClick={() => setPopupModal(true)}>Add Tax</Button>
      </div>

      <Table columns={columns} dataSource={taxesData} bordered />


      {
        popupModal && (
          <Modal 
            // title={`${editTaxes !== null ? 'Edit Tax' : 'Add New Tax'}`}
            title={
              <div>
                  {editTaxes !== null ? 'Edit Tax' : 'Add New Tax'}
                  <CloseButton onClick={() => { setEditTaxes(null); setPopupModal(false); }} />
              </div>
            }
            open={popupModal}
            onCancel={() => {
              setEditTaxes(null);
              setPopupModal(false);
            }}
            footer={false}>
            <Form layout="vertical" initialValues={editTaxes} onFinish={handleSubmit}>
              <Form.Item
                name="name"
                label="Tax Name"
                rules={[
                  { required: true, message: 'Please input the tax name!' },
                  { min: 3, message: 'Tax name must be at least 3 characters long!' }
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="rate"
                label="Tax Rate"
                rules={[
                  { required: true, message: 'Please input the tax rate!' },
                  { min: 0, max: 100, message: 'Tax rate must be between 0 and 100!' }
                ]}
              >
                <Input type="number" />
              </Form.Item>

              <Form.Item
                name="description"
                label="Description"
              // Optional description, so no required rule
              >
                <Input />
              </Form.Item>

              <div className="d-flex justify-content-end ant-modal-footer">
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </div>
            </Form>
          </Modal>
        )
      }

    </DefaultLayout>
  );



};

export default TaxesSetting;