import React, { useState, useEffect } from "react";
import tableImage from '../assets/images/chair.png';
import { Popconfirm, Card, Modal, Button, Table, Tag, Collapse, message, Badge } from 'antd';
import axios from 'axios';
import QRCode from 'qrcode.react';
import {
  DeleteOutlined,
  CheckOutlined,
  EditOutlined,
} from "@ant-design/icons";
//import PullToRefresh from 'react-pull-to-refresh';
import OrderReceipt from './OrderReceipt';
import './TableList.css';
import CloseButton from "../components/CloseButton";

const TableList = ({ table }) => {

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const { Meta } = Card;
  const [open, setOpen] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [orders, setOrders] = useState([]);
  const [tableNumber, setTableNumber] = useState(table.table_number);
  const [tableOrders, setTableOrders] = useState({ orders: [] });
  const [editStatus, setEditStatus] = useState(null);
  const [qrVisible, setQrVisible] = useState(false);
  const [taxes, setTaxes] = useState([]);  
  const [orderReceiptVisible, setOrderReceiptVisible] = useState(false);
  // const [selectedOrders, setSelectedOrders] = useState([]);
  const [deliveredOrders, setDeliveredOrders] = useState([]);
  const [pollingInterval, setPollingInterval] = useState(null);
  const [selectedOrders, setSelectedOrders] = useState(() => {
    const storedValue = localStorage.getItem('selectedOrders');
    return storedValue ? JSON.parse(storedValue) : [];
  });
  const [firstOrderID, setFirstOrderID] = useState(null);
  const [firstOrderIDs, setFirstOrderIDs] = useState({});
  const [initialCheckArray, setInitialCheckArray] = useState(() => {
    const storedValue = localStorage.getItem('initialCheckArray');
    return storedValue ? JSON.parse(storedValue) : []; // Retrieve the stored array from localStorage
  });

  useEffect(() => {
    localStorage.setItem('selectedOrders', JSON.stringify(selectedOrders));
  }, [selectedOrders]);

  const [editOrderVisible, setEditOrderVisible] = useState(false);
  const [currentOrder, setCurrentOrder] = useState(null);

  const handleEditOrder = (order) => {
    setCurrentOrder(order);
    setEditOrderVisible(true);
  };
  
  const getTotalItems = () => {
    return orders.reduce((total, order) => {
      if (Array.isArray(order.result)) {
        return total + order.result.reduce((acc, curr) => {
          return acc + (curr.orderArray_quantity || 0); // Default to 0 if undefined
        }, 0);
      }
      return total; // If order.result is not an array, return the current total
    }, 0).toFixed(0);
  };
  const totalItems = getTotalItems();
  
  const handleUpdateOrder = async (order) => {
    // Validate input
    if (!order._id || order.orderArray_quantity < 0 || typeof order.orderArray_quantity !== "number") {
      message.error("Please provide a valid quantity.");
      return;
    }
    
    if (order.orderArray_remark && typeof order.orderArray_remark !== "string") {
      message.error("Please provide a valid remark.");
      return;
    }
  
    try {
      // Prepare payload for the new controller
      const payload = {
        _id: order._id,
        orderArray_quantity: order.orderArray_quantity,
        orderArray_remark: order.orderArray_remark,
      };
  
      console.log("Updating order with payload:", payload);
  
      const response = await axios.put("/api/orders/update-order-array", payload);
  
      if (response.status === 200) {
        message.success("Order array item updated successfully!");
        getAllOrders(); // Refresh the orders after updating
      } else {
        message.error(response.data.message || "Failed to update order array item.");
      }
    } catch (error) {
      console.error("Error updating order array item:", error);
      message.error(error.response?.data?.message || "Something went wrong while updating the order array item.");
    }
  };
  

  // Modify getAllOrders to handle both initial load and polling updates
  const getAllOrders = async () => {
    try {
      const { data } = await axios.get("/api/orders/get-order");
      setOrderData(data);
      console.log('Orders data refreshed');
    } catch (error) {
      console.log(error);
    }
  };

  // Add polling setup in useEffect
  useEffect(() => {
    // Initial data fetch
    getAllOrders();
    fetchTableOrders();

    const interval = setInterval(() => {
      getAllOrders();
      fetchTableOrders();
    }, 5000); // Poll every 5 seconds

    // Store interval ID
    setPollingInterval(interval);

    // Cleanup function to clear interval when component unmounts
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  const onChange = (key) => {
    console.log('onChange key:',key);
  };

  const fetchTableOrders = async () => {
    try {
      let fetchedTableOrders = [];
      const { data: ordersData } = await axios.get(`/api/orders/get-order?table=${tableNumber}`);
      ordersData.forEach(order => {
        const { order_tablenumber } = order;
        if (!fetchedTableOrders[order_tablenumber]) {
          fetchedTableOrders[order_tablenumber] = [];
        }
        fetchedTableOrders[order_tablenumber].push(order);
      });
  
      setTableOrders(fetchedTableOrders);
    
      // Loop through all fetched orders and handle the first order of each table
      for (const tableNumber in fetchedTableOrders) {
        const tableOrders = fetchedTableOrders[tableNumber];
        if (tableOrders.length > 0) {
          const orderIds = tableOrders.map(order => order.oid);
          // console.log(`Order IDs for table ${tableNumber}:`, orderIds);
  
          const firstOrderId = orderIds[0];
          // console.log(`First Order ID for table ${tableNumber}:`, firstOrderId);
  
          // Update the firstOrderIDs state with the first order for the current table
          setFirstOrderIDs((prevState) => ({
            ...prevState,
            [tableNumber]: firstOrderId,
          }));
        }
      }
    } catch (error) {
      // console.log("Error fetching table orders:", error);
      message.error("Error fetching table orders", error);
    }
  };
  
  useEffect(() => {
    // Loop through the firstOrderIDs for each table
    for (const tableNumber in firstOrderIDs) {
      const firstOrderId = firstOrderIDs[tableNumber];
  
      if (firstOrderId && !initialCheckArray.includes(firstOrderId)) {
        // Add firstOrderId to initialCheckArray if not already included
        const updatedCheckArray = [...initialCheckArray, firstOrderId];
        setInitialCheckArray(updatedCheckArray);
  
        // Save to localStorage
        localStorage.setItem('initialCheckArray', JSON.stringify(updatedCheckArray));
  
        // Log the updated initialCheckArray
        // console.log("Updated initialCheckArray:", updatedCheckArray);
  
        // Simulate a 'checked' event for the first order of each table
        const simulatedEvent = { target: { checked: true } };
        handleCheckboxChange(simulatedEvent, firstOrderId);
      }
    }
  }, [firstOrderIDs, initialCheckArray]); 

  // useEffect(() => {
  //   console.log("Selected Orders:", selectedOrders);
  // }, [selectedOrders]);

  // useEffect(() => {
  //   fetchTableOrders();
  // }, [tableNumber]);

  const handleRefresh = async () => {
    try {
      await fetchTableOrders();  // Now accessible outside useEffect
      message.success('Orders refreshed successfully!');
    } catch (error) {
      console.error("Error refreshing orders:", error);
      message.error("Failed to refresh orders.");
    }
  };

  const fetchData = async (tableNumber) => {
    try {
      const ordersData = tableOrders[tableNumber] || [];
      if (JSON.stringify(ordersData) !== JSON.stringify(orders)) {
        console.log('Orders data retrieved');
        setOrders(ordersData);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(table.table_number);
  }, [fetchData, table.table_number]);

  const fetchTaxes = async () => {
    try {
      const response = await axios.get('/api/taxes/get-taxes');
      setTaxes(response.data);  // Assuming the API returns an array of tax objects
    } catch (error) {
      console.error("Error fetching taxes:", error);
      message.error("Failed to fetch taxes.");
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const showModal = () => {
    fetchTaxes();  // Fetch taxes when opening the modal
    setOpen(true);
  };

  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 3000);
  };

  const handleEditOrderStatus = async (status) => {
    try {
      const ordersToUpdate = selectedOrders.map((orderId) => ({
        orderId: orderId,
        order_progressstatus: status,
      }));
  
      if (ordersToUpdate.length === 0) {
        console.warn('No orders selected for updating.');
        return;
      }
  
      // Send a single request with the orders array
      const response = await axios.put('/api/orders/edit-order', {
        orders: ordersToUpdate,
      });
  
      if (response.status === 200) {
        console.log('Orders updated successfully');
        // Add delivered orders to state
        setDeliveredOrders(prev => [...prev, ...selectedOrders]);
        // Optionally refresh the page or update the orders list
        window.location.reload();
      } else {
        console.error('Some orders failed to update');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleEditOrderStatusKitchen = async (status) => {
    try {
      // Filter orders to update only those that are selected
      const ordersToUpdate = selectedOrders.map((orderId) => ({
        orderId: orderId,
        order_progressstatus: status, // Pass the desired status ("Kitchen", "Receive", etc.)
      }));
  
      if (ordersToUpdate.length === 0) {
        console.warn('No orders selected for updating.');
        return;
      }
  
      // Send a single request with the orders array
      const response = await axios.put('/api/orders/edit-order', {
        orders: ordersToUpdate,
      });
  
      if (response.status === 200) {
        console.log('Orders updated successfully');
        
      } else {
        console.error('Some orders failed to update');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  

  const handleEditOneOrderStatus = async (value, status) => {
    try {
      await axios.put("/api/orders/edit-order", { orderArrayId: value._id, order_progressstatus: status })
        .then(response => {
          console.log(response.data);
          message.success("Order Updated Successfully!");
          setEditStatus(null);


        })
        .catch(error => {
          console.log(error);
          message.error("Something went wrong!");
        });
    } catch (error) {
      console.log(error);
      message.error("Something went wrong!");
    }
  };

  const handleDeleteOrder = async (record) => {
    try {
      await axios.post("/api/orders/delete-order", { orderArrayId: record._id });
      message.success('Order Deleted Successfully')
      getAllOrders();
    } catch (error) {
      message.error('Something Went Wrong')
      console.log(error);
    }
  }

  const handleReceiptClick = async () => {
    try {
      const url = `/bills?table=${tableNumber}`;
      window.location.href  = url;
    } catch (error) {
      console.error("Error updating the order status:", error);
      message.error("Failed to update order status.");
    }
  };

  const orderReceiptClick = () => {
    try {
      setOrderReceiptVisible(true);  // This makes the modal visible
      console.log('Receipt Modal should be visible now.');
    } catch (error) {
      console.error("Error showing receipt modal:", error);
      message.error("Failed to show receipt modal.");
    }
  };

  const calculateTotalWithTaxes = (order) => {
    const totalWithoutTax = order.result.reduce(
      (acc, curr) => acc + curr.orderArray_price * curr.orderArray_quantity,
      0
    );
    const totalTax = taxes.reduce((acc, tax) => acc + totalWithoutTax * (tax.rate / 100), 0);
    return (totalWithoutTax + totalTax).toFixed(2);
  };

  const calculateTotalWithoutTaxes = (order) => {
    const totalWithoutTax = order.result.reduce(
      (acc, curr) => acc + curr.orderArray_price * curr.orderArray_quantity,
      0
    );
   
    return (totalWithoutTax).toFixed(2);
  };

  const { Panel } = Collapse;

  const showQrModal = () => {
    setQrVisible(true);
  };

  const hideQrModal = () => {
    setQrVisible(false);
  };

     // Handle checkbox change
  // const handleCheckboxChange = (event, orderId) => {
  //   if (event.target.checked) {
  //     // Add order to selected list
  //     setSelectedOrders((prev) => [...prev, orderId]);
  //   } else {
  //     // Remove order from selected list
  //     setSelectedOrders((prev) => prev.filter((id) => id !== orderId));
  //   }
  // };  

  const handleCheckboxChange = (event, orderId) => {
    if (event.target.checked) {
      // Add order to selected list
      setSelectedOrders((prev) => [...prev, orderId]);
    } else {
      // Remove order from selected list
      setSelectedOrders((prev) => prev.filter((id) => id !== orderId));
    }
  };

  useEffect(() => {
    fetchTableOrders();
  }, [table.table_number]);

  // Calculate aggregated totals for selected orders
  const getTotalWithTaxes = () => {
    return orders
      .filter((order) => selectedOrders.includes(order.oid)) // Only selected orders
      .reduce((total, order) => total + parseFloat(calculateTotalWithTaxes(order)), 0)
      .toFixed(2); // Format to 2 decimal places
  };

  const getTotalWithoutTaxes = () => {
    return orders
      .filter((order) => selectedOrders.includes(order.oid)) // Only selected orders
      .reduce((total, order) => total + parseFloat(calculateTotalWithoutTaxes(order)), 0)
      .toFixed(2); // Format to 2 decimal places
  };

  const getTotalTaxes = () => {
    return orders
      .filter((order) => selectedOrders.includes(order.oid)) // Only selected orders
      .reduce((total, order) => {
        const totalWithTaxes = parseFloat(calculateTotalWithTaxes(order));
        const totalWithoutTaxes = parseFloat(calculateTotalWithoutTaxes(order));
        return total + (totalWithTaxes - totalWithoutTaxes); // Add the tax difference
      }, 0)
      .toFixed(2); // Format to 2 decimal places
  };

  const totalTaxes = getTotalTaxes();
  const totalWithTaxes = getTotalWithTaxes();
  const totalWithoutTax = getTotalWithoutTaxes();

  // Initialize selected orders with all orders when the component mounts
  // useEffect(() => {
  //   setSelectedOrders(orders.map((order) => order.oid)); // Set all order IDs as selected
  // }, [orders]); // Re-run when orders data changes

  const statusBadge = (status) => {
    let color;
    switch (status) {
      case 'Kitchen':
        color = 'yellow';
        break;
      case 'Receive':
        color = 'green';
        break;
      case 'Preparing':
        color = 'blue';
        break;
      default:
        color = 'gray';
    }
    return <Badge.Ribbon color={color} text={status} />;
  };

  return (
    // <PullToRefresh onRefresh={handleRefresh} className="page-container">
    <div>
      {/* <Card
        hoverable
        style={{ width: 230, marginBottom: 20,

          backgroundColor: tableOrders[table.table_number] &&
          tableOrders[table.table_number].some(order => order.order_status)
          ? 'rgba(255, 0, 0, 0.2)' // Apply red background with transparency if table is busy
          : 'rgba(153, 255, 153, 0.2)', // Default background when available

          // border: tableOrders[table.table_number] &&
          // tableOrders[table.table_number].some(order => order.order_status)
          // ? '2px solid red' // Apply red border if table is busy
          // : '2px solid green', // Default border when available

         }}
        cover={<img alt={table.table_number} src={tableImage} />}
        onClick={() => showModal(table.table_number)}
      >
        <Meta
          title={`Table ${table.table_number}`}
          description={
            <>
              <span
                style={{
                  color: tableOrders[table.table_number] &&
                    tableOrders[table.table_number].some(order => order.order_status)
                    ? "red"
                    : "green",
                  fontWeight: "bold",
                }}
              >
                {tableOrders[table.table_number] &&
                  tableOrders[table.table_number].some(order => order.order_status)
                  ? "Busy"
                  : "Available"}
              </span>
            </>
          }
        />
      </Card> */}


      <div className="card-size">
      <Card
          hoverable
          style={{
            marginBottom: 20,
            borderRadius: 8,
            backgroundColor: table.table_status ? 
              (tableOrders[table.table_number] && tableOrders[table.table_number].some(order => order.order_status)
                ? 'rgba(255, 0, 0, 0.2)' // Apply red background if table is busy
                : 'rgba(153, 255, 153, 0.2)') // Default background when available
              : 'rgba(200, 200, 200, 0.5)', // Grey when not available
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
            transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Smooth hover transition
          }}
              cover={
                  <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                    <img alt={table.table_number} src={tableImage} style={{ width: '100%', height: '100%', objectFit: 'cover', borderTopLeftRadius: 8, borderTopRightRadius: 8 }} />
                    <div style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)', // Move the element 50% to the left and top of its own width and height
                        fontSize: 24,
                        fontWeight: 'bold',
                        color: 'white',
                        zIndex: 1,
                        // backgroundColor: 'rgba(0, 0, 0, 0.5)', // Add a semi-transparent black background
                        backgroundColor: table.table_status ? 
                            (tableOrders[table.table_number] && tableOrders[table.table_number].some(order => order.order_status)
                                    ? "rgb(210, 4, 45)"//'rgba(255, 0, 0, 0.2)' // Apply red background if table is busy
                                    : "rgb(50, 205, 50)")//'rgba(153, 255, 153, 0.2)', // Default background when available
                                :'rgba(200, 200, 200)',
                        borderRadius: '50%', // Make it a circle
                        padding: '12px 25px', // Add some padding to make it look better
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        {table.table_number}
                    </div>
                  </div>
              }
                onClick={table.table_status ? () => showModal(table.table_number) : null}
                //   onMouseEnter={(e) => e.target.style.transform = 'scale(1.05)'} // Slight zoom effect on hover
                //   onMouseLeave={(e) => e.target.style.transform = 'scale(1)'} // Reset zoom when mouse leaves
                >
                
                <Meta
                    title={`Table ${table.table_number}`}
                    description={
                      <span
                        style={{
                            color: table.table_status ? 
                                (tableOrders[table.table_number] && tableOrders[table.table_number].some(order => order.order_status)
                                ? "red"
                                : "green")
                                : "grey",
                            fontWeight: "bold",
                            fontSize: '14px', // Slightly smaller font size for the description
                            display: 'block',
                            marginTop: '5px',
                        }}
                        >
                        {table.table_status ? 
                            (tableOrders[table.table_number] && tableOrders[table.table_number].some(order => order.order_status)
                                ? "Busy"
                                : "Available")
                            : "Unavailable"}
                      </span>
          }
       />
        {totalItems > 0 && (
            <div style={{
                position: 'absolute',
                bottom: 20, // Adjust as needed
                right: 20, // Adjust as needed
                backgroundColor: 'rgba(0, 0, 0, 0.7)', // Semi-transparent background for better visibility
                color: 'white',
                width: 30, // Set a fixed width
                height: 30, // Set a fixed height
                borderRadius: '50%', // Make it a circle
                display: 'flex', // Use flexbox to center the text
                justifyContent: 'center', // Center horizontally
                alignItems: 'center', // Center vertically
                textAlign: 'center',
                fontSize: 16,
                zIndex: 2, // Ensure it appears above other elements
            }}>
                {totalItems}
            </div>
          )}
      </Card>
      </div>

      <Modal
        open={open}
        title={
          <div>
          {/* <button
            type="button"
            aria-label="Close"
            className="ant-modal-close"
            onClick={handleCancel} // Function to close the modal
          >
            <span className="ant-modal-close-x">
              <span
                role="img"
                aria-label="close"
                className="anticon anticon-close ant-modal-close-icon"
              >
                <svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  data-icon="close"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                </svg>
              </span>
            </span>
          </button> */}
          <CloseButton onClick={handleCancel} />
          {`Table ${table.table_number}`}
        </div>
        
        }
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
        className="fixed-footer"
        footer={[
          <Button
            key="showQR"
            type="default"
            onClick={showQrModal}
          >
            Show QR
          </Button>,

          <Button
            key="back"
            onClick={() => {
              window.open(`https://pos.ergrouptech.com/shop?table=${table.table_number}`, '_blank');
            }}
          >
            Order More
          </Button>,

          <Button
          type="primary"
          onClick={() => {
            if (selectedOrders.length > 0) {
              orderReceiptClick();  
              handleEditOrderStatusKitchen("Kitchen");
            }
          }}
          disabled={
            selectedOrders.length === 0 ||
            orders.filter(order => 
              selectedOrders.includes(order.oid) && 
              (
                //order.order_progressstatus === "Kitchen" || 
                order.order_progressstatus === "Receive")
            ).length === selectedOrders.length
          }
          style={{ 
            opacity: orders.filter(order => 
              selectedOrders.includes(order.oid) && 
              (
                //order.order_progressstatus === "Kitchen" || 
                order.order_progressstatus === "Receive")
            ).length === selectedOrders.length ? 0.5 : 1
          }}
          >
          {orders.filter(order => 
            selectedOrders.includes(order.oid) && 
            (
              //order.order_progressstatus === "Kitchen" || 
              order.order_progressstatus === "Receive")
          ).length === selectedOrders.length ? 'Already Sent to Kitchen' : 'Print to Kitchen'}
          </Button>,

          <Button
          key="primary"
          style={{ 
            color: "green",
            opacity: orders.filter(order => 
              selectedOrders.includes(order.oid) && 
              order.order_progressstatus === "Receive"
            ).length === selectedOrders.length ? 0.5 : 1
          }}
          onClick={() => handleEditOrderStatus("Receive")}
          disabled={
            selectedOrders.length === 0 || 
            orders.filter(order => 
              selectedOrders.includes(order.oid) && 
              order.order_progressstatus === "Receive"
            ).length === selectedOrders.length
          }
          >
          {orders.filter(order => 
            selectedOrders.includes(order.oid) && 
            order.order_progressstatus === "Receive"
          ).length === selectedOrders.length ? 'Already Delivered' : 'Delivered'}
          </Button>,
          
          <Button key="submit" type="primary" 
          danger loading={loading} 
          onClick={handleReceiptClick}
          disabled={tableOrders[table.table_number] &&
              tableOrders[table.table_number].some(order => order.order_status)
              ? false
              : true}
          >
            Checkout
          </Button>
        ]}
      >
        <div style={{ marginBottom: 16 }}>
          <Button
            type="default"
            onClick={() => {
              const allOrders = orders.map(order => order.oid);
              setSelectedOrders(allOrders);
            }}
            disabled={selectedOrders.length === orders.length}
          >
            Select All
          </Button>
          <Button
            type="default"
            onClick={() => {
              setSelectedOrders([]);
            }}
            disabled={selectedOrders.length === 0}
            style={{ marginLeft: 8 }}
          >
            Deselect All
          </Button>
        </div>
        <Collapse         
            defaultActiveKey={orders.map((order) => order.oid)} 
            onChange={onChange}
            collapsible="icon">
                  <span //style={{ marginLeft: '10px' }}
                  >
                    {statusBadge((order) => order.order_progressstatus)}
                  </span>
       
            {orders.map((order) => {
              const totalWithTaxes = calculateTotalWithTaxes(order); // Calculate total with taxes for the current order
            
              // Only render the Panel if the total with taxes is greater than 0
              if (totalWithTaxes > 0) {
                return (
                  <Panel
                    header={
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          type="checkbox"
                          checked={selectedOrders.includes(order.oid)}
                          onChange={(e) => {
                            handleCheckboxChange(e, order.oid);
                          }}
                          style={{ marginRight: '8px' }} // Optional spacing
                        />
                        <strong>Order ID: #{order.oid}</strong>
                      </div>
                    }
                    key={order.oid}
                  >
                    <Table
                      bordered
                      pagination={false}
                      columns={[
                        {
                          title: 'No',
                          key: 'index',
                          render: (text, record, recordIndex) => (
                            <span>{recordIndex + 1}</span>
                          ),
                        },
                        {
                          title: "Food Name",
                          dataIndex: "orderArray_productName",
                          render: (orderArray_productName) => orderArray_productName,
                        },
                        {
                          title: "Price(RM)",
                          dataIndex: "orderArray_price",
                          render: (orderArray_price) => `RM${parseFloat(orderArray_price).toFixed(2)}`,
                        },
                        {
                          title: "Quantity",
                          dataIndex: "orderArray_quantity",
                          render: (orderArray_quantity) => orderArray_quantity,
                        },
                        {
                          title: "Remark",
                          dataIndex: "orderArray_remark",
                          render: (orderArray_remark) => orderArray_remark,
                        },
                        {
                          title: "Status",
                          dataIndex: "order.order_progressstatus",
                          render: (order_progressstatus) => {
                            let displayText = "";
                            let color = "";
                            let backgroundColor = "";
                            let borderColor = "";
            
                            if (order.order_progressstatus === "") {
                              displayText = "Ordered";
                              color = "#d4380d";
                              backgroundColor = "#fff2e8";
                              borderColor = "#ffbb96";
                            } else if (order.order_progressstatus === "Kitchen") {
                              displayText = "Sent to Kitchen";
                              color = "#d4b106";
                              backgroundColor = "#fffbe6";
                              borderColor = "#ffe58f";
                            } else if (order.order_progressstatus === "Receive") {
                              displayText = "Delivered";
                              color = "green";
                              backgroundColor = "#f6ffed";
                              borderColor = "#b7eb8f";
                            }
            
                            return (
                              <Tag
                                style={{
                                  color: color,
                                  backgroundColor: backgroundColor,
                                  borderColor: borderColor,
                                }}
                              >
                                {displayText}
                              </Tag>
                            );
                          },
                        },
                        {
                          title: "Total Price(RM)",
                          dataIndex: "orderArray_totalPrice",
                          render: (_, record) => (
                            <span>RM{(record.orderArray_price * record.orderArray_quantity).toFixed(2)}</span>
                          ),
                        },
                        {
                          title: "Actions",
                          render: (_, record) => (
                            <div>
                              <EditOutlined
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleEditOrder(record)}
                              />
                              <Popconfirm
                                title="Are you sure you want to delete this record?"
                                onConfirm={() => handleDeleteOrder(record)}
                                okText="Yes"
                                cancelText="No"
                              >
                                <DeleteOutlined
                                  style={{ cursor: "pointer", marginLeft: 8 }}
                                />
                              </Popconfirm>
                            </div>
                          ),
                        },
                      ]}
                      dataSource={order.result || []}
                    />
            
                    <div style={{ textAlign: "right", marginTop: "1rem", fontSize: "1.3em" }}>
                      Total Price: RM{" "}
                      <span style={{ fontWeight: "bold", fontSize: "1.1em" }}>
                        {calculateTotalWithoutTaxes(order)}
                      </span>
                    </div>
            
                    <div style={{ textAlign: "right", fontSize: "1.3em" }}>
                      Total Tax: RM{" "}
                      <span style={{ fontWeight: "bold", fontSize: "1.1em" }}>
                        {(calculateTotalWithTaxes(order) - calculateTotalWithoutTaxes(order)).toFixed(2)}
                      </span>
                    </div>
            
                    <div style={{ textAlign: "right", marginTop: "1rem", fontSize: "1.5em" }}>
                      Total Price with Taxes: RM{" "}
                      <span style={{ fontWeight: "bold", fontSize: "1.3em" }}>
                        {calculateTotalWithTaxes(order)}
                      </span>
                    </div>
                  </Panel>
                );
              }
            })}
        </Collapse>

        <>
      
        {/* Display totals */}
        <div className="totals-section">
          <p>
            Total for Selected Orders (Without Taxes):  
            <strong style={{ fontSize: "1.5em" }}> RM {totalWithoutTax}</strong>
          </p>

          <p>
            Total Tax for Selected Orders:  
            <strong style={{ fontSize: "1.5em" }}> RM {totalTaxes} </strong>
          </p>

          <p style={{ fontSize: "1.2em" }}>
            Total for Selected Orders (With Taxes): 
            <strong style={{ fontSize: "1.8em" }}> RM {totalWithTaxes}</strong>
          </p>
        </div>
      </>


      </Modal>

      <Modal
        open={editOrderVisible}
        title="Edit Order"
        onCancel={() => setEditOrderVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setEditOrderVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={async () => {
              // Call the function to update the order in the database
              await handleUpdateOrder(currentOrder);
              setEditOrderVisible(false);
            }}
          >
            Save
          </Button>,
        ]}
      >
        {currentOrder && (
          <div>
            <div>
              <label>Food Name: </label>
              <span>{currentOrder.orderArray_productName}</span>
            </div>
            {/* <div>
              <label>Price (RM):</label>
              <input
                type="number"
                value={currentOrder.orderArray_price}
                onChange={(e) => setCurrentOrder({ ...currentOrder, orderArray_price: parseFloat(e.target.value) })}
              />
            </div> */}
            <div>
              <label>Quantity:</label>
              <input
                type="number"
                value={currentOrder.orderArray_quantity}
                onChange={(e) => setCurrentOrder({ ...currentOrder, orderArray_quantity: parseInt(e.target.value) })}
              />
            </div>
            <div>
              <label>Remark:</label>
              <input
                type="text"
                value={currentOrder.orderArray_remark}
                onChange={(e) => setCurrentOrder({ ...currentOrder, orderArray_remark: e.target.value })}
              />
            </div>
          </div>
        )}
      </Modal>

      <Modal
        open={qrVisible}
        onOk={hideQrModal}
        onCancel={hideQrModal}
        footer={[
          <Button
            key="print"
            type="primary"
            onClick={() => window.print()}
          >
            Print
          </Button>
        ]}
      >
        <div style={{ textAlign: "center", fontSize: "18px", fontWeight: "bold" }}>
          QR Code
        </div>
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <QRCode value={`https://pos.ergrouptech.com/shop?table=${table.table_number}`} />
        </div>
        <div style={{ textAlign: "center", marginTop: "10px", fontSize: "18px", fontWeight: "bold" }}>
          Table {table.table_number}
        </div>
      </Modal>

      <Modal
        open={orderReceiptVisible}  // Changed from "visible"
        onCancel={() => setOrderReceiptVisible(false)}
        footer={[
          <Button key="close" onClick={() => setOrderReceiptVisible(false)}>
            Close
          </Button>
        ]}
        title={
          <div>
          Order Receipt
          <CloseButton onClick={() => setOrderReceiptVisible(false)} />
          </div>
        }
      >
        <OrderReceipt orders={orders} selectedOrders={selectedOrders} tableNumber={table.table_number} /> 
        
      </Modal>
    
       {/* <OrderReceipt orders={orders} selectedOrders={selectedOrders} tableNumber={table.table_number} />  */}

    </div>

    
    // </PullToRefresh>
  );
};

export default TableList;


