import React from 'react';
import { Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const PrivateRoute = ({ children, role }) => {
  const auth = JSON.parse(localStorage.getItem('auth'));

  if (!auth) {
    // Not logged in
    return <Navigate to="/login" />;
  }

  const decodedToken = jwtDecode(auth.token);
  const userRole = decodedToken.role;

  if (role && auth.role !== role) {
    // Role does not match
    return <Navigate to="/" />;
  }

  // Role matches, render the component
  return children;
};

export default PrivateRoute;