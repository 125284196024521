import { jwtDecode } from 'jwt-decode';

export const getUserRole = () => {
  const auth = JSON.parse(localStorage.getItem('auth'));
  if (!auth) {
    return null;
  }
  //console.log('test ',auth);
  
  const decodedToken = jwtDecode(auth.token);
  //console.log('decoded', decodedToken);
  
  return decodedToken.role;
};