import React, { useEffect, useState } from "react";
import { Button, Layout, Menu, Dropdown, message } from "antd";
import { Link,useNavigate } from "react-router-dom";
import { CaretDownOutlined, CaretUpOutlined, DownOutlined } from '@ant-design/icons';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  MenuOutlined,
  UserOutlined,
  LogoutOutlined,
  HomeOutlined,
  CopyOutlined,
  FileTextOutlined,
  SettingOutlined,
  TeamOutlined,
  AppstoreOutlined,
  UnorderedListOutlined,
  TableOutlined,
} from "@ant-design/icons";
import "../styles/DefaultLayout.css";
import { getUserRole } from '../utils/auth';
import { jwtDecode } from 'jwt-decode';

const { Header, Sider, Content } = Layout;
const SubMenu = Menu.SubMenu;


const DefaultLayout = ({ children }) => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const userRole = getUserRole();


  // Handle logout logic
  const handleLogout = () => {
    // Remove 'auth' item from localStorage
    localStorage.removeItem('auth');

    // Navigate to login page
    navigate('/login');
  };

   // Dropdown menu
  //  const menu = (
  //   <Menu>
  //     <Menu.Item onClick={handleLogout}>
  //       Logout
  //     </Menu.Item>
  //   </Menu>
  // );
   const menu = (
    <Menu>
      <Menu.Item key="logout" onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  const menuItems = [
    {
      key: '1',
      label: 'Logout',
      onClick: handleLogout,
    },
  ];

  const handleDropdownClick = () => {
    setIsOpen(!isOpen);
  };
  
  // // Check for current logged-in user
  // useEffect(() => {
  //   const auth = JSON.parse(localStorage.getItem('auth'));
  //   if (auth) {
  //     const { token } = auth;
  //     try {
  //       const decoded = jwtDecode(token);
  //       setCurrentUser(decoded.userId); // Set the current user
  //       navigate('/');
  //     } catch (err) {
  //       console.error('Invalid token', err);
  //       localStorage.removeItem('auth');
  //     }
  //   }
  // }, [navigate]);

  useEffect(() => {
    const auth = JSON.parse(localStorage.getItem('auth'));
    if (auth) {
      const { token } = auth;
      try {
        const decoded = jwtDecode(token);
        setCurrentUser(decoded.userId); // Set the current user
        
        // Navigate only if the user is on an invalid page
        if (window.location.pathname === '/login') {
          navigate('/'); // Redirect to the default page only if on login
        }
      } catch (err) {
        console.error('Invalid token', err);
        localStorage.removeItem('auth');
        navigate('/login'); // Redirect to login if token is invalid
      }
    } else {
      navigate('/login'); // Redirect to login if no auth is found
    }
  }, [navigate]);
  


  return (
    <Layout>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          width: collapsed ? 80 : 200, // Fixed sidebar width
        }}
      >
        <div className="logo">
          <h1 className="text-center text-light font-weight-bold mt-4">POS</h1>
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={window.location.pathname}
        >
          <Menu.Item key="/" icon={<HomeOutlined />}>
            <Link to="/">Table</Link>
          </Menu.Item>
          <Menu.Item key="/menu" icon={<MenuOutlined />}>
            <Link to="/items">Menu</Link>
          </Menu.Item>
          <Menu.Item key="/category" icon={<AppstoreOutlined />}>
            <Link to="/category">Category</Link>
          </Menu.Item>
          <Menu.Item key="/bills" icon={<CopyOutlined />}>
            <Link to="/bills">Bills</Link>
          </Menu.Item>
          {userRole === 'admin' && (
            <>
              <Menu.Item key="/report" icon={<FileTextOutlined />}>
                <Link to="/report">Report</Link>
              </Menu.Item>
              <SubMenu
                theme="dark"
                key="SubMenu"
                title="Setting"
                icon={<SettingOutlined />}
              >
                <Menu.Item key="table">
                  <Link to="/tables">Tables</Link>
                </Menu.Item>
                <Menu.Item key="tax">
                  <Link to="/SetTax">Taxes</Link>
                </Menu.Item>
              </SubMenu>
              <Menu.Item key="/staff" icon={<UserOutlined />}>
                <Link to="/staff">Staff</Link>
              </Menu.Item>
            </>
          )}
        </Menu>
      </Sider>

      <Layout
        className="site-layout"
        style={{
          marginLeft: collapsed ? 80 : 200, // Sidebar margin
        }}
      >
        <Header
          className="site-layout-background"
          style={{
            marginLeft:'',
            margin: '0px 0px 0px',
            padding: 0,
            position: 'fixed',
            width: collapsed ? 'calc(100% - 80px)' : 'calc(100% - 210px)', // Match sidebar width
            zIndex: 10,
          }}
        >
          <Dropdown menu={{ items: menuItems }} trigger={['click']} onVisibleChange={(visible) => setMenuVisible(visible)}>
            <Button
              style={{
                float: 'right',
                marginTop: '15px',
                marginRight: '10px',
                backgroundColor: '#4CAF50',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                padding: '8px 20px',
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              }}
            >
              <span>Hello, {currentUser || 'User'}</span>
              {/* <DownOutlined style={{ fontSize: '0px' }}
              /> */}
              {/* <span style={{ marginLeft: '8px', fontSize: '10px' }}>▼</span>             */}
              <svg
                style={{
                  marginLeft: "8px",
                  transform: menuVisible ? "rotate(0deg)" : "rotate(90deg)",
                  transition: "transform 0.3s ease",
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M1.5 5.5l6.5 6.5 6.5-6.5h-13z"
                />
               </svg>
            </Button>
          </Dropdown>
          
          {/* <Dropdown
            menu={{ items: menuItems }}
            trigger={['click']}
            onClick={handleDropdownClick}
          >
            <Button
              style={{
                float: 'right',
                marginTop: '15px',
                marginRight: '10px',
                backgroundColor: '#4CAF50',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                // padding: '8px 20px',
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              }}
            >
              <span>Hello, {currentUser || 'User'}</span>
              {isOpen ? (
                <CaretUpOutlined  style={{ marginLeft: '15px' }} /> 
              ) : (
                <CaretDownOutlined style={{ marginLeft: '15px' }} /> 
              )}
            </Button>
          </Dropdown> */}
        </Header>

        <Content
          className="site-layout-background"
          style={{
            margin: '64px 0px 24px', // Fixed header margin
            //marginLeft: collapsed ? 80 : 200, // Match header margin
            padding: 24,
            //minHeight: 280,
            transition: 'margin-left 0.2s ease', // Smooth transition when collapsing sidebar
            minHeight:'auto',
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>

  );
};
export default DefaultLayout;