import React, { useEffect, useState } from "react";
import DefaultLayout from '../components/DefaultLayout';
import axios from 'axios';
import { Row, Col } from 'antd';

import BillList from "../components/BillList";


const Bills = () => {

  const [tablesData, setTablesData] = useState([]);
  useEffect(() => {
    const getAllItems = async () => {
      try {
        const { data } = await axios.get("/api/tables/get-table");

        setTablesData(data);
        console.log(data);

      } catch (error) {
        console.log(error);
      }

    };
    getAllItems();
  }, []);

  return (
    <DefaultLayout>

      <Row>
        {
          tablesData.map((table, index) => (
            <Col key={index} xs={24} lg={6} md={12} sm={6}>

              <BillList table={table}></BillList>
            </Col>

          ))
        }
      </Row>
    </DefaultLayout>
  );
};

export default Bills;