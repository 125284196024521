import React, { useState, useEffect } from "react";
import { Popconfirm, Modal, Button, Table, Form, Input, Select, message } from "antd";

import axios from "axios";
import DefaultLayout from '../components/DefaultLayout';
import CloseButton from "../components/CloseButton";


import {
    DeleteOutlined,
    EditOutlined,
    CaretUpOutlined, CaretDownOutlined
} from "@ant-design/icons";

const TableSetting = () => {

    const [tablesData, setTablesData] = useState([]);
    const [popupModal, setPopupModal] = useState(false);
    const [editTable, setEditTable] = useState(null);
    const [statusOpen, setStatusOpen] = useState(false);

    const handleStatusDropdownVisibleChange = (open) => {
        setStatusOpen(open);
    };

    const getAllTables = async () => {
        try {

            const { data } = await axios.get("/api/tables/get-table");

            setTablesData(data);

            console.log(data);

        } catch (error) {
            console.log(error);
        }

    };
    useEffect(() => {

        getAllTables();
    }, []);


    //handle delete
    const handleDelete = async (record) => {
        try {


            await axios.post("/api/tables/delete-table", { tableId: record._id });
            message.success('Table Deleted Successfully')
            getAllTables();
            setPopupModal(false);

        } catch (error) {
            message.success('Something Went Wrong')
            console.log(error);
        }
    }

    //able data
    const columns = [
        { title: "Table Number", dataIndex: "table_number" },
        { title: "Table Capacity", dataIndex: "table_capacity" },
        { title: "Table Status", dataIndex: "table_status", render: (status) => status ? 'Available' : 'Not Available' },
        { title: "Remark", dataIndex: "remark" },
        {
            title: "Actions",
            dataIndex: "_id",
            render: (id, record) => (
                <div>
                    <EditOutlined
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            setEditTable(record);
                            setPopupModal(true);
                        }}
                    />
                    <Popconfirm
                        title="Are you sure you want to delete this record?"
                        onConfirm={() => handleDelete(record)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <DeleteOutlined
                            style={{ cursor: "pointer", marginLeft: 8 }}
                        />
                    </Popconfirm>
                </div>
            ),
        },
    ];

    //handle submit
    const handleSubmit = async (value) => {
    if (editTable === null) {
        try {
            const dateTime = new Date();
            const createdAt = dateTime.toLocaleString();
            const newValues = { ...value, createdAt };
            const res = await axios.post("/api/tables/add-table", newValues);
            message.success('Table Added Successfully');
            getAllTables();
            setPopupModal(false);
        } catch (error) {
            // console.error("Error details:", error);
            if (error.response) {
                // console.error("Response status:", error.response.status);
                // console.error("Response data:", error.response.data);
                if (error.response.data.message === "Table with this number already exists") {
                    message.error('Table with this number already exists');
                } else {
                    message.error('Something Went Wrong');
                }
            } else {
                message.error('Network Error');
            }
        }
    } else {
        try {
            const payload = { ...value, tableId: editTable._id };
            // console.log("Payload being sent:", payload);
            await axios.put("/api/tables/edit-table", payload);
            message.success('Table Updated Successfully');
            getAllTables();
            setPopupModal(false);
        } catch (error) {
            // console.error("Error details:", error);
            if (error.response) {
                // console.error("Response status:", error.response.status);
                // console.error("Response data:", error.response.data);
                if (error.response.data.message === "Table with this number already exists") {
                    message.error('Table with this number already exists');
                } else {
                    message.error('Something Went Wrong');
                }
            } else {
                message.error('Network Error');
            }
        }
    }
};

    return (
        <DefaultLayout>
            <div className="d-flex justify-content-between">
                <h1>Table List</h1>
                <Button type="primary" onClick={() => setPopupModal(true)}>Add Table</Button>
            </div>

            <Table columns={columns} dataSource={tablesData} bordered />


            {
                popupModal && (
                    <Modal 
                        // title={`${editTable !== null ? 'Edit Table' : 'Add New Table'}`}
                        title={
                            <div>
                                {editTable !== null ? 'Edit Table' : 'Add New Table'}
                                <CloseButton onClick={() => { setEditTable(null); setPopupModal(false); }} />
                            </div>
                          }
                        open={popupModal}
                        onCancel={() => {
                            setEditTable(null);
                            setPopupModal(false);
                        }}
                        footer={false}>
                        <Form layout="vertical" initialValues={editTable} onFinish={handleSubmit}>
                        <Form.Item
                            name="table_number"
                            label="Table Number"
                            rules={[
                                { required: true, message: 'Please input the table number!' },
                                {
                                    validator: (_, value) => {
                                        if (value < 0) {
                                            return Promise.reject(new Error('Table capacity must be at least 0!'));
                                        }
                                        return Promise.resolve();
                                    },
                                },
                            ]}
                        >
                            <Input type="number" min={1} />
                        </Form.Item>
                            <Form.Item
                                name="table_capacity"
                                label="Table Capacity"
                                rules={[
                                    { required: true, message: 'Please input the table capacity!' },
                                    {
                                        validator: (_, value) => {
                                            if (value < 1) {
                                                return Promise.reject(new Error('Table capacity must be at least 1!'));
                                            }
                                            return Promise.resolve();
                                        },
                                    },
                                ]}
                            >
                                <Input type="number" min={1} />
                            </Form.Item>

                            {/* <Form.Item
                                name="table_status"
                                label="Availability"
                                rules={[{ required: true, message: 'Please select the table status!' }]}
                            >
                                <Select>
                                    <Select.Option value={true}>Available</Select.Option>
                                    <Select.Option value={false}>Not Available</Select.Option>
                                </Select>
                            </Form.Item> */}

                            <Form.Item
                                name="table_status"
                                label="Availability"
                                rules={[{ required: true, message: 'Please select the table status!' }]}
                            >
                                <Select
                                onDropdownVisibleChange={handleStatusDropdownVisibleChange } 
                                suffixIcon={statusOpen  ? <CaretUpOutlined /> : <CaretDownOutlined />} 
                                >
                                <Select.Option value={true}>Available</Select.Option>
                                <Select.Option value={false}>Not Available</Select.Option>
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name="remark"
                                label="Remark"
                            // Optional remark, so no required rule
                            >
                                <Input />
                            </Form.Item>

                            <div className="d-flex justify-content-end ant-modal-footer">
                                <Button type="primary" htmlType="submit">
                                    Save
                                </Button>
                            </div>
                        </Form>
                    </Modal>
                )
            }

        </DefaultLayout>
    );



};

export default TableSetting;