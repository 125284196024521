import React, { useState, useEffect } from "react";
import { Popconfirm, Modal, Button, Table, Form, Input, message, Upload } from "antd";
import axios from "axios";
import DefaultLayout from '../components/DefaultLayout';
import { DeleteOutlined, EditOutlined,UploadOutlined } from "@ant-design/icons";
import CloseButton from "../components/CloseButton";
import './SetCategory.css';
import imageCompression from 'browser-image-compression';

const CategorySetting = () => {
  const [categoriesData, setCategoriesData] = useState([]);
  const [popupModal, setPopupModal] = useState(false);
  const [editCategory, setEditCategory] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [fileUpload, setFile] = useState(null);
  const [previousImageKey, setPreviousImageKey] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [originalImage ,setOriginalImageURLEdit] = useState(null);
  const [isUploading, setIsUploading] = useState(false);  
  const [uploadTimeout, setUploadTimeout] = useState(null);
  var [countImageUploaded, setCountImage] = useState(0);

  const getAllCategory = async () => {
    try {
      const { data } = await axios.get("/api/menus/get-category");
      setCategoriesData(data);
      // console.log(data);
    } catch (error) {
      message.error('Something Went Wrong');
      // console.log(error);
    }
  };

  useEffect(() => {
    getAllCategory();
  }, []);

  useEffect(() => {
    // This effect will run when the editCategory is set (when fetching data for editing)
    if (editCategory && editCategory.image) {
      // If there's an existing image, set it as the initial image
      const extractedFilename = editCategory.image.match(/[^/]+$/)[0];
      setImageUrl(editCategory.image);
      setPreviousImageKey(new URL(editCategory.image).pathname.slice(1)); // Extract the key from the URL
      // Initialize the fileList with the fetched image (for display in the Upload component)
      setFileList([
        {
          uid: "-1",  // Unique identifier for the file
          name: extractedFilename,  // Display name
          status: "done",  // Mark it as uploaded
          url: editCategory.image,  // Set the fetched image URL
        },
      ]);
    }
  }, [editCategory]);

  // useEffect(() => {
  //   console.log("Image", imageUrl);
  //   console.log("Original Image", originalImage);
  // }, [imageUrl, originalImage]);

  const columns = [
    {
      title: 'No',
      key: 'index',
      render: (text, record, recordIndex) => (
        <span>{recordIndex + 1}</span>
      ),
    },
    { title: "Category Name", dataIndex: "name" },
    {
      title: "Image",
      dataIndex: "image",
      render: (image, record) => (
        <img src={record.image} alt={record.name} height="60" width="60" />
      ),
    },
    {
      title: "Actions",
      dataIndex: "_id",
      render: (id, record) => (
        <div>
          {/* <EditOutlined
            style={{ cursor: "pointer" }}
            onClick={() => {
              setEditCategory(record);
              setPopupModal(true);
            }}
          /> */}
          <EditOutlined
            style={{ cursor: "pointer" }}
            onClick={() => openModal(record)}
          />
          <Popconfirm
            title="Are you sure you want to delete this record?"
            onConfirm={() => handleDelete(record)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined
              style={{ cursor: "pointer", marginLeft: 8 }}
            />
          </Popconfirm>
        </div>
      ),
    },
  ];

  // const deleteImageFromS3 = async (key) => {
  //   // console.log("Deleting key:", key); // Debug
  //   if (!key) {
  //     console.error("No key provided for deletion");
  //     return; // Prevent calling the API with a null/undefined key
  //   }
  //   try {
  //     // console.log("Start Perform API");
  //     // await axios.post("http://localhost:8080/api/delete", {
  //     //   data: { key },
  //     // });
  //     await axios.post("http://localhost:8080/api/image/delete", { key }, {
  //       headers: {
  //         'Content-Type': 'application/json', // Ensure JSON payload
  //       },
  //     });
  //     // console.log("Done.");
  //     // message.success("Image deleted successfully");
  //   } catch (error) {
  //     // console.error("Failed to delete image:", error);
  //     // console.error("Failed to delete image. Error:", error.response?.data || error.message);
  //     // message.error("Failed to delete image");
  //     // 
  //   }
  // };

  const openModal = (category = null) => {
    if (category) {
      setEditCategory(category); // Set the category to edit
      setImageUrl(category.image); // Set the existing image URL
      // console.log(" Image",imageUrl);
      if(category.image){
        setPreviousImageKey(new URL(category.image).pathname.slice(1)); // Extract S3 key from URL
      }
      
      setOriginalImageURLEdit(category.image);
      // console.log("Original Image",originalImage);
      // Populate the fileList with the existing image
      setFileList([
        {
          uid: "-1", // Unique identifier for the file
          name: "Existing Image", // Display name
          status: "done", // Mark it as uploaded
          url: category.image, // Existing image URL
        },
      ]);
    } else {
      setEditCategory(null); // For adding a new category
      setImageUrl(null); // Clear image URL
      setPreviousImageKey(null); // Clear the previous image key
      setOriginalImageURLEdit(null);
      setFileList([]); // Clear the file list
    }
  
    setPopupModal(true); // Open the modal
  };

  const handleDelete = async (record) => {
    try {
      await axios.post("/api/menus/delete-category", { categoryId: record._id });
      message.success('Category Deleted Successfully');
      getAllCategory();
      setPopupModal(false);
    } catch (error) {
      message.error('Something Went Wrong');
      // console.log(error);
    }
  };

  const handleCancel = async () => {
    // Prevent canceling if upload is still in progress
    if (isUploading) {
      message.error("Please wait until the image upload is complete before canceling.");
      return;
    }

    // try {
      
    // } catch (error) {
    //   message.error("Something went wrong.");
    // } finally {
      // Reset states after canceling
      // setCountImage(0);
      setOriginalImageURLEdit(null);
      setFile(null);
      setImageUrl(null);
      setFileList([]);
      setEditCategory(null); // Clear the edit state
      setPopupModal(false); // Close the modal
    // }
  };
  
  const handleSubmit = async (values) => {
    if (isUploading) {
      message.warning("Please wait until the upload is complete before submitting your form.");
      return false; // Prevent form submission while uploading
    }
  
    const updatedValues = { ...values, slug: values.name, image: imageUrl };
  
    try {
      if (editCategory === null) {
        const dateTime = new Date();
        const createdAt = dateTime.toLocaleString();
        const newValues = { ...updatedValues, createdAt };
  
        // Only upload image to S3 when submitting
        // console.log("First see URL:", fileUpload);
        try {
          const uploadedImageUrl = await uploadImageToS3(fileUpload);
          newValues.image = uploadedImageUrl;
        } catch (uploadError) {
          // If image upload fails, clear the file list and stop submission
          message.error("Image upload failed. Please try again.");
          setFile(null);
          setFileList([]); // Clear the file list
          return; // Stop the submission process
        }
  
        await axios.post("/api/menus/add-category", newValues);
        message.success('Category Added Successfully');
      } else {
        const updatedValuesWithImage = { ...updatedValues, categoryId: editCategory._id };
  
        // console.log(imageUrl);
        // console.log(originalImage);
        // If image URL has changed, upload new image to S3 and remove old image
        if (imageUrl !== originalImage && imageUrl != null) {
          
          try {
            updatedValuesWithImage.image = await uploadImageToS3(fileUpload);
          } catch (uploadError) {
            // If image upload fails, clear the file list and stop submission
            message.error("Image upload failed. Please try again.");
            setFile(null);
            setFileList([]); // Clear the file list
            return; // Stop the submission process
          }
        }else{
          updatedValuesWithImage.image = originalImage;
        }
  
        // If image is not set, keep the original
      
        // if (!imageUrl && originalImage) {
        //   updatedValuesWithImage.image = originalImage; // Keep original image URL
        // } else {
        //   updatedValuesWithImage.image = await uploadImageToS3(imageUrl); // Upload new image
        // }
  
        await axios.put("/api/menus/edit-category", updatedValuesWithImage);
        message.success('Category Updated Successfully');
      }
  
      setImageUrl(null); // Clear the image after submission
      setFileList([]); // Clear the file list
      setCountImage(0);
      getAllCategory(); // Refresh category list
      setPopupModal(false); // Close the modal
      setEditCategory(null); // Reset edit category
    } catch (error) {
      message.error('Something Went Wrong');
      console.log(error);
    }
  };

  const handleImageUpload = async (file) => {
    const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    if (!allowedTypes.includes(file.type)) {
      message.error("Invalid file type. Only JPG, JPEG, and PNG are allowed.");
      return;
    }
    
    // Check if file size exceeds 20MB
    if (file.size > 20 * 1024 * 1024) {
      message.error("File size exceeds 20MB limit. Please choose a smaller file.");
      return;
    }
  
    // Initialize the file with status 'uploading' and 0% progress for preview purposes
    setFileList((prevList) => [
      ...prevList,
      {
        uid: file.uid,
        name: file.name,
        status: "uploading", // Image is uploading
        percent: 0,          // Set progress to 0 initially
      },
    ]);
  
    setIsUploading(true); // Set uploading to true
  
    // Compress the image before upload
    const options = {
      maxSizeMB: 1, // Adjust compression size (1MB target)
      maxWidthOrHeight: 1920, // Optionally resize to max dimensions
      useWebWorker: true,
    };
  
    let compressedFile;
    let timeout;
  
    try {
      compressedFile = await imageCompression(file, options);
    } catch (err) {
      message.error("Image compression failed.");
      setIsUploading(false);
      return;
    }
  
    try {
      // Create a local object URL for preview purposes
      const localUrl = URL.createObjectURL(compressedFile);
      setImageUrl(localUrl); // Set the image URL for preview
      setFile(compressedFile);
  
      setFileList((prevList) =>
        prevList.map((item) =>
          item.uid === file.uid ? { ...item, status: "done", url: localUrl } : item
        )
      );
      message.success("Image ready for upload!");
  
      // Start a timeout for the upload process
      timeout = setTimeout(() => {
        message.error("Upload is taking too long. Please try again.");
        setIsUploading(false);
        setFileList((prevList) =>
          prevList.map((item) =>
            item.uid === file.uid ? { ...item, status: "error" } : item
          )
        );
      }, 30000); // 30 seconds timeout
      setUploadTimeout(timeout); // Store the timeout ID
    } catch (error) {
      message.error("Image upload failed! Please try again.");
      setFileList((prevList) =>
        prevList.map((item) =>
          item.uid === file.uid ? { ...item, status: "error" } : item
        )
      );
    } finally {
      // Ensure timeout is cleared after handling the upload result
      if (timeout) {
        clearTimeout(timeout);
      }
      setIsUploading(false); // Reset uploading status
    }
  };
  
  const uploadImageToS3 = async (file) => {
    try {
      const formData = new FormData();
      formData.append("image", file); // Append the file object directly
  
      const response = await axios.post("/api/image/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
  
      if (response.data && response.data.url) {
        message.success("Image uploaded successfully!");
        return response.data.url; // Return the URL of the uploaded image
      } else {
        // If the response is invalid or does not contain the URL
        throw new Error("Failed to upload image. Please try again.");
      }
    } catch (error) {
      // message.error(error.response?.data?.message || "Image upload failed. Please try again.");
      console.error("Image upload error:", error);
      throw error; // Re-throw the error to be caught in the outer try-catch
    }
  };

  return (
    <DefaultLayout>
      <div className="d-flex justify-content-between">
        <h1>Category List</h1>
        {/* <Button type="primary" onClick={() => setPopupModal(true)}>Add Category</Button> */}
        <Button type="primary" onClick={() => openModal()}>Add Category</Button>
      </div>
      <Table columns={columns} dataSource={categoriesData} bordered />
      {
        popupModal && (
          <Modal
            // title={`${editCategory !== null ? 'Edit Category' : 'Add New Category'}`}
            title={
              <div>
                  {editCategory !== null ? 'Edit Category' : 'Add New Category'}
                  {/* <CloseButton onClick={() => { setEditCategory(null); setPopupModal(false); }} /> */}
                  <CloseButton onClick={handleCancel } />
              </div>
            }
            open={popupModal}
            onCancel={handleCancel}
            footer={false}
          >
            <Form layout="vertical" initialValues={editCategory} onFinish={handleSubmit}>
              <Form.Item
                name="name"
                label="Category Name"
                rules={[{ required: true, message: 'Please input the category name!' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="image"
                label="Image Upload"
                rules={[{ required: true, message: "Please upload an image!" }]}
              >
                {/* handle previous image */}
                <Upload
                  className="upload-container"
                  listType="picture"
                  fileList={fileList}
                  customRequest={({ file }) => handleImageUpload(file)}
                  onRemove={async (file) => {
                    if (isUploading) {
                      message.warning("Please wait until the upload is complete before removing the file.");
                      return false; // Prevent the removal
                    }

                    if(file.url && editCategory){
                      setFileList([]);
                    }
                    // if (file.url && previousImageKey &&!editCategory) {
                    //   try {
                    //     // console.log("Deleting previous image key:", previousImageKey);
                    //     await deleteImageFromS3(previousImageKey);
                    //     setPreviousImageKey(null);
                    //     // message.success("Image removed successfully!");
                    //   } catch (error) {
                    //     // console.error("Failed to delete image:", error);
                    //     // message.error("Failed to remove image.");
                    //   }
                    // }
                    setFile(null);
                    setFileList([]);
                    setImageUrl(null);
                  }}
                >
                  {fileList.length < 1 && <Button  icon={<UploadOutlined />} className="upload-button">Upload Image</Button>}
                </Upload>
              </Form.Item>
              <div className="d-flex justify-content-end ant-modal-footer">
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </div>
            </Form>
          </Modal>
        )
      }
    </DefaultLayout>
  );
};

export default CategorySetting;
