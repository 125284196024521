import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Payment.css';
import cashImage from '../assets/images/cash.png';
import visaImage from '../assets/images/visa.png';
import tngImage from '../assets/images/tng.png';
import duitnowImage from '../assets/images/duitnow.png';
import backspaceImage from '../assets/images/backspace.png';
import { setPaymentData, setIsValidated } from '../utils/paymentData';
import { useValidation } from '../contexts/ValidationContext';

const PaymentPage = ({ tableNumber, totalWithTaxes, orders, onPaymentValidation }) => {
  const [paymentMethod, setPaymentMethod] = useState(null); // null = no method selected
  const [cashAmount, setCashAmount] = useState(''); // Store cash input as string for easier appending
  const [discountAmount, setDiscountAmount] = useState('');
  // const [amountToPay, setAmountToPay] = useState(totalWithTaxes); // Example amount to pay
  const [transactionId, setTransactionId] = useState(''); // Transaction ID for transfer methods
  const [isDiscountMode, setIsDiscountMode] = useState(false);
  const [discountType, setDiscountType] = useState('RM');
  const { updateValidation } = useValidation();
  const [isValidateDisabled, setIsValidateDisabled] = useState(true);

  console.log("amount",totalWithTaxes);
  
  const handleNumberInput = (value) => {
    if (isDiscountMode) {
      setDiscountAmount((prevAmount) => {
        if (prevAmount.includes('.') && prevAmount.split('.')[1].length >= 2) {
          return prevAmount;
        }
        return prevAmount + value;
      });
    } else {
      setCashAmount((prevAmount) => {
        // Allow only up to 2 decimal places
        if (prevAmount.includes('.') && prevAmount.split('.')[1].length >= 2) {
          return prevAmount; // Prevent further decimals after 2
        }
        return prevAmount + value; // Append the new digit to the cash amount
      });
    }
    
  };

  const toggleDiscountMode = () => {
    setIsDiscountMode((prevMode) => !prevMode);
  };


  const handleQuickAdd = (value) => {
    if (isDiscountMode) {
      setDiscountAmount((prevAmount) => {
        const currentAmount = parseFloat(prevAmount || '0');
        return (currentAmount + value).toFixed(2);
      });
    } else {
      setCashAmount((prevAmount) => {
        const currentAmount = parseFloat(prevAmount || '0');
        return (currentAmount + value).toFixed(2);
      });
    }
  };

  // Calculate final total after discount
  const calculateTotalAfterDiscount = () => {
    const discountValue = parseFloat(discountAmount || '0');
    if (discountType === '%') {
      // Percentage discount calculation
      return totalWithTaxes - (totalWithTaxes * discountValue) / 100;
    }
      // Fixed RM discount calculation
      return totalWithTaxes - discountValue;
  };

const totalAfterDiscount = (Math.round(calculateTotalAfterDiscount() * 20) / 20).toFixed(2);

  useEffect(() => {
    if (parseFloat(cashAmount || '0') >= totalAfterDiscount || transactionId.trim() !== '') {
      setIsValidateDisabled(false);
    } else {
      setIsValidateDisabled(true);
    }
  }, [totalAfterDiscount, cashAmount, transactionId]);
 

  // Calculate balance for cash payments
  const calculateBalance = () => {
    const totalAfterDiscount = (Math.round(calculateTotalAfterDiscount() * 20) / 20).toFixed(2);
    return parseFloat(cashAmount || '0') - totalAfterDiscount;
  };

  // Handle transaction ID input
  const handleTransactionIdChange = (e) => {
    setTransactionId(e.target.value);
  };

  // Handle payment method selection
  const selectPaymentMethod = (method) => {
    setPaymentMethod(method);
    setCashAmount(''); // Reset cash amount when switching methods
    setTransactionId(''); // Reset transaction ID
  };

  // Handle clearing the cash / discount input
  const clearInput = () => {
    if (isDiscountMode) {
      setDiscountAmount('');
    } else {
      setCashAmount('');
    }
  };


  const addDecimal = () => {
    if (isDiscountMode) {
      setDiscountAmount((prev) => (!prev.includes('.') ? prev + '.' : prev));
    } else {
      setCashAmount((prev) => (!prev.includes('.') ? prev + '.' : prev));
    }
  };

  // Function to handle backspace (removes last character)
  const handleBackspace = () => {
    if (isDiscountMode) {
      setDiscountAmount((prevAmount) => prevAmount.slice(0, -1));
    } else {
      setCashAmount((prevAmount) => prevAmount.slice(0, -1));
    }
  };

  const handleValidate = () => {
    const totalAfterDiscount = calculateTotalAfterDiscount();
    if (parseFloat(cashAmount) < totalAfterDiscount) {
      return;
    }
    submitPayment();
  };

  // Function to submit the payment
  const submitPayment = async () => {
    const totalAfterDiscount = calculateTotalAfterDiscount();

    const paymentData = {
      method: paymentMethod,
      transactionId: paymentMethod === 'cash' ? null : transactionId,
      amount: paymentMethod === 'cash' ? parseFloat(cashAmount) : totalWithTaxes,
      discount: {
        type: discountType,
        value: discountAmount ? parseFloat(discountAmount) : 0.00,
      },
      orderDetails: orders,
    };

    setPaymentData(cashAmount, totalWithTaxes, totalAfterDiscount);
    updateValidation(true, tableNumber);

    console.log('Payment Data:', paymentData); // Log to ensure it's correct

    try {
        const response = await axios.post('/api/payments', paymentData);
        console.log('Payment stored successfully:', response.data);
    } catch (error) {
        console.error('Error storing payment:', error);
    }

    onPaymentValidation(paymentData);
    console.log("Payment Data:", paymentData)
  };

  return (
    <div className="payment-container">

<>
    <style>
    {`
    /* Container Styling */
    // .payment-container {
    //   font-family: 'Poppins', sans-serif;
    //   max-width: 800px;
    //   margin: 50px auto;
    //   padding: 20px;
    //   background: linear-gradient(145deg, #ffffff, #f7f9fc);
    //   border-radius: 20px;
    //   box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    //   color: #2c3e50;
    // }

    .payment-header {
      text-align: center;
      margin-bottom: 20px;
    }

    .table-info h2 {
      font-size: 2rem;
      color: #34495e;
    }

    /* Payment Method Buttons */
    .payment-methods {
      display: flex;
      gap: 15px;
      justify-content: center;
      margin-bottom: 30px;
    }

    .payment-button {
      background: #ecf0f1;
      border: 2px solid transparent;
      padding: 15px;
      border-radius: 12px;
      text-align: center;
      transition: all 0.3s ease;
      cursor: pointer;
      flex: 1;
    }

    .payment-button:hover {
      background: #dfe6e9;
      transform: translateY(-5px);
    }

    .payment-button.selected {
      background: #3498db;
      color: #fff;
      border-color: #2980b9;
    }

    .payment-icon {
      width: 60px;
      margin-bottom: 10px;
    }

    .payment-button span {
      display: block;
      font-size: 1rem;
      font-weight: bold;
    }

    /* Payment Center */
    .payment-center {
      text-align: center;
      padding: 20px;
    }

    .cash-payment .total-display h3 {
      font-size: 1.8rem;
      margin-bottom: 10px;
      color: #2c3e50;
    }

    .cash-payment .total-display h4 {
      font-size: 1.5rem;
      margin-top: 15px;
    }

    /* Numpad Styling */
    .numpad {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 10px;
      margin-top: 20px;
    }

    .numpad-button {
      padding: 15px;
      font-size: 1.2rem;
      border-radius: 8px;
      border: 1px solid #bdc3c7;
      background: #ffffff;
      color: #000000;
      transition: background-color 0.3s ease, transform 0.2s ease;
      cursor: pointer;
    }

    .numpad-button:hover {
      background: #f1f1f1;
      transform: scale(1.05);
    }

    .numpad-button.focused {
      background-color: #3498db;
      color: #ffffff;
      border: 1px solid #2980b9;
    }

    /* Transaction Section */
    .transaction-id-section {
      background: #f9f9f9;
      padding: 20px;
      border-radius: 12px;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    }

    .transaction-id-section h3 {
      font-size: 1.5rem;
      color: #34495e;
      margin-bottom: 15px;
    }

    .transaction-input {
      width: 100%;
      padding: 12px;
      border-radius: 8px;
      border: 1px solid #bdc3c7;
      margin-bottom: 15px;
    }

    .submit-button {
      padding: 10px 20px;
      background: #3498db;
      color: white;
      font-size: 1rem;
      border-radius: 8px;
      border: none;
      cursor: pointer;
      transition: all 0.3s ease;
    }

    .submit-button:hover {
      background: #2980b9;
    }

    /* Responsiveness */
    @media (max-width: 768px) {
      .payment-container {
        padding: 15px;
      }

      .payment-button {
        padding: 10px;
      }

      .numpad-button {
        padding: 10px;
      }
    }

    .backspace-icon {
      //display: flex;
      justify-content: center;  /* Aligns the image horizontally */
      align-items: center;      /* Aligns the image vertically */
      padding: 4px;
      width: 40px;
      height: 30px;
    }
      
      .pay-button {
        background-color: #4CAF50 !important;
        color: white !important;
        border: 1px solid #4CAF50 !important;
        font-weight: bold;
        padding: 15px;
        font-size: 1.2rem;
        border-radius: 8px;
        transition: background-color 0.3s ease, transform 0.2s ease;
        cursor: pointer;
        grid-column: span 3;
      }

      .pay-button:hover {
        background: #f1f1f1;
        transform: scale(1.05);
      }

      .pay-button:disabled {
        background-color: #d4d4d4 !important; 
        color: #a1a1a1 !important;
        border: 1px solid #c6c6c6 !important;
        cursor: not-allowed !important;
        transform: none;
      }

    `}
    </style>
    </>

      <header className="payment-header">
        <div className="table-info">
          <h2>Table {tableNumber}</h2>
        </div>
      </header>

      <div className="payment-body">
        {/* Payment Method Selection */}
        <div className="payment-methods">
          {[ 
            { method: 'cash', img: cashImage, label: 'Cash' },
            { method: 'transfer2', img: visaImage, label: 'Visa' },
            { method: 'transfer3', img: tngImage, label: 'TnG' },
            { method: 'transfer4', img: duitnowImage, label: 'Duitnow' }
          ].map(({ method, img, label }) => (
            <button
              key={method}
              onClick={() => selectPaymentMethod(method)}
              className={`payment-button ${paymentMethod === method ? 'selected' : ''}`}
            >
              <img src={img} alt={`${label} Logo`} className="payment-icon" />
              <span>{label}</span>
            </button>
          ))}
        </div>

        {/* Payment Center */}
        <div className="payment-center">
          {paymentMethod === 'cash' && (
            <div className="cash-payment">
              <div className="total-display">
              <h3>Final Total: <strong>RM {(Math.round(calculateTotalAfterDiscount() * 20) / 20).toFixed(2)}</strong></h3>
                <p>Total: <strong>RM {totalWithTaxes}</strong></p>
                <p
                  style={{
                    border: isDiscountMode ? 'none' : '2px solid #3498db',
                    padding: '5px',
                    borderRadius: '5px',
                  }}
                >Cash Entered: <strong>RM {cashAmount || '0.00'}</strong></p>
                <p
                  style={{
                    border: isDiscountMode ? '2px solid #e67e22' : 'none',
                    padding: '5px',
                    borderRadius: '5px',
                  }}
                >Discount: 
                  {discountType === 'RM' ? (
                    <strong> {discountType} {discountAmount || '0.00'}</strong>
                  ) : (
                    <strong> {discountAmount || '0.00'} {discountType}</strong>
                  )}
                </p>
                <h4>Balance: <span style={{ color: calculateBalance() >= 0 ? '#27ae60' : '#e74c3c' }}>
                    RM {calculateBalance() >= 0 ? calculateBalance().toFixed(2) : '0.00'}
                  </span>
                </h4>
              </div>

              <div className="numpad">
              {(isDiscountMode ? [1, 2, 3, '%', 4, 5, 6, 'RM', 7, 8, 9, 'Clear', 0, '.', 'Backspace', 'Back'] 
                :[1, 2, 3, "+10", 4, 5, 6, "+50", 7, 8, 9, "+100", 0, '.', 'Backspace', 'Clear', 'Validate','Discount']).map((value, index) => (
                  <button
                    key={index}
                    onClick={() => {
                      if (typeof value === 'number') {
                        handleNumberInput(value.toString()); // For normal numbers
                        updateValidation(false, tableNumber);
                      } else if (value.toString().startsWith('+')) {
                        handleQuickAdd(parseInt(value.slice(1))); // For "+10", "+20", "+50"
                        updateValidation(false, tableNumber);
                      } else if (value === '%') {
                        setDiscountType('%');
                        updateValidation(false, tableNumber);
                      } else if (value === 'RM') {
                        setDiscountType('RM');
                        updateValidation(false, tableNumber);
                      } else if (value === '.') {
                        addDecimal(); // Add decimal functionality
                        updateValidation(false, tableNumber);
                      } else if (value === 'Clear') {
                        clearInput(); // Clear input
                        updateValidation(false, tableNumber);
                      } else if (value === 'Backspace') {
                        handleBackspace(); // Backspace functionality
                        updateValidation(false, tableNumber);
                      } else if (value === 'Validate') {
                        handleValidate(); // Submit payment
                      } else if (value === 'Discount' || value === 'Back') {
                        toggleDiscountMode();
                      }
                    }}
                    className={`numpad-button ${
                      value === 'Back' && isDiscountMode ? 'focused' : ''
                    } ${value === 'Validate' ? 'pay-button' : ''}`}
                    disabled={value === 'Validate' ? isValidateDisabled : false}
                  >
                    {/* {value} */}
                    {value === 'Backspace' ? (
                      <img src={backspaceImage} alt="Backspace" className="backspace-icon" />
                    ) : (
                      value
                    )}
                  </button>
                ))}
              </div>
            </div>
          )}

{(paymentMethod === 'transfer2' || paymentMethod === 'transfer3' || paymentMethod === 'transfer4') && (
            <div className="transaction-id-section">
              <h3>Final Total: <strong>RM {(Math.round(calculateTotalAfterDiscount() * 20) / 20).toFixed(2)}</strong></h3>
              <p>Total: <strong>RM {totalWithTaxes}</strong></p>
              <p
                  style={{
                    border: isDiscountMode ? '2px solid #e67e22' : 'none',
                    padding: '5px',
                    borderRadius: '5px',
                  }}
                >Discount:
                  {discountType === 'RM' ? (
                    <strong> {discountType} {discountAmount || '0.00'}</strong>
                  ) : (
                    <strong> {discountAmount || '0.00'} {discountType}</strong>
                  )}
                </p>
              <p>Enter Transaction ID:</p>
              <input
                type="text"
                value={transactionId}
                onChange={handleTransactionIdChange}
                placeholder="Transaction ID"
                className="transaction-input"
              />
              {/* <button className="submit-button" onClick={submitPayment}>
                Submit
              </button> */}
              <div className="numpad">
              {(isDiscountMode ? [1, 2, 3, '%', 4, 5, 6, 'RM', 7, 8, 9, 'Clear', 0, '.', 'Backspace', 'Back']
                :['Submit','Discount']).map((value, index) => (
                  <button
                    key={index}
                    onClick={() => {
                      if (typeof value === 'number') {
                        handleNumberInput(value.toString()); // For normal numbers
                        updateValidation(false, tableNumber);
                      } else if (value.toString().startsWith('+')) {
                        handleQuickAdd(parseInt(value.slice(1))); // For "+10", "+20", "+50"
                        updateValidation(false, tableNumber);
                      } else if (value === '%') {
                        setDiscountType('%');
                        updateValidation(false, tableNumber);
                      } else if (value === 'RM') {
                        setDiscountType('RM');
                        updateValidation(false, tableNumber);
                      } else if (value === '.') {
                        addDecimal(); // Add decimal functionality
                        updateValidation(false, tableNumber);
                      } else if (value === 'Clear') {
                        clearInput(); // Clear input
                        updateValidation(false, tableNumber);
                      } else if (value === 'Backspace') {
                        handleBackspace(); // Backspace functionality
                        updateValidation(false, tableNumber);
                      } else if (value === 'Submit') {
                        submitPayment(); // Submit payment
                      } else if (value === 'Discount' || value === 'Back') {
                        toggleDiscountMode();
                      }
                    }}
                    className={`numpad-button ${
                      value === 'Back' && isDiscountMode ? 'focused' : ''
                    } ${value === 'Submit' ? 'pay-button' : ''}`}
                    disabled={value === 'Submit' ? isValidateDisabled : false}
                  >
                    {/* {value} */}
                    {value === 'Backspace' ? (
                      <img src={backspaceImage} alt="Backspace" className="backspace-icon" />
                    ) : (
                      value
                    )}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;



// import React, { useState } from 'react';
// import axios from 'axios'; // Import axios for making HTTP requests
// import './Payment.css';

// const PaymentPage = ({ tableNumber, totalWithTaxes, orders, onPaymentValidation }) => {
//   const [paymentMethod, setPaymentMethod] = useState(null); // null = no method selected
//   const [cashAmount, setCashAmount] = useState(''); // Store cash input as string for easier appending
//   const [amountToPay, setAmountToPay] = useState(totalWithTaxes); // Example amount to pay
//   const [transactionId, setTransactionId] = useState(''); // Transaction ID for transfer methods

//   // Handle numpad input for cash payments (append digits)
//   const handleCashInput = (value) => {
//     setCashAmount((prevAmount) => prevAmount + value); // Append the new digit to the cash amount
//   };

//   // Handle quick addition of +10, +20, +50
//   const handleQuickAdd = (value) => {
//     setCashAmount((prevAmount) => {
//       const currentAmount = parseFloat(prevAmount || '0'); // Convert existing amount to number
//       return (currentAmount + value).toString(); // Add the value and convert back to string
//     });
//   };

//   // Calculate balance for cash payments
//   const calculateBalance = () => {
//     return parseFloat(cashAmount || '0') - amountToPay;
//   };

//   // Handle transaction ID input
//   const handleTransactionIdChange = (e) => {
//     setTransactionId(e.target.value);
//   };

//   // Handle payment method selection
//   const selectPaymentMethod = (method) => {
//     setPaymentMethod(method);
//     setCashAmount(''); // Reset cash amount when switching methods
//     setTransactionId(''); // Reset transaction ID
//   };

//   // Handle clearing the cash input
//   const clearCashInput = () => {
//     setCashAmount('');
//   };

//   // Function to submit the payment
//   const submitPayment = async () => {
//     const paymentData = {
//         method: paymentMethod, // This should be defined based on user selection
//         transactionId: paymentMethod === 'cash' ? null : transactionId,
//         amount: paymentMethod === 'cash' ? parseFloat(cashAmount) : amountToPay,
//         orderDetails: orders, // Include order details if applicable
//     };

//     console.log('Payment Data:', paymentData); // Log to ensure it's correct

//     try {
//         const response = await axios.post('http://localhost:8080/api/payments', paymentData);
//         console.log('Payment stored successfully:', response.data);
//     } catch (error) {
//         console.error('Error storing payment:', error);
//     }

//     onPaymentValidation(paymentData);
// };

//   return (
//     <div className="payment-container">
//       <header className="payment-header">
//         <div className="table-info">
//             <h2>Table {tableNumber}</h2>
//         </div>
//       </header>

//       <div className="payment-body">
//         {/* Payment Method Selection */}
//         <div className="payment-methods">
//             <button
//                 onClick={() => selectPaymentMethod('cash')}
//                 className={paymentMethod === 'cash' ? 'selected' : ''}
//             >
//                 Cash
//             </button>
//             <button
//                 onClick={() => selectPaymentMethod('transfer2')}
//                 className={paymentMethod === 'transfer2' ? 'selected' : ''}
//             >
//                 Visa
//             </button>
//             <button
//                 onClick={() => selectPaymentMethod('transfer3')}
//                 className={paymentMethod === 'transfer3' ? 'selected' : ''}
//             >
//                 Tng
//             </button>
//             <button
//                 onClick={() => selectPaymentMethod('transfer4')}
//                 className={paymentMethod === 'transfer4' ? 'selected' : ''}
//             >
//                 Duitnow
//             </button>
//         </div>

//         {/* Display Cash Calculator or Transaction ID based on selected payment method */}
//         <div className="payment-center">
//           {paymentMethod === 'cash' && (
//             <>
//               <div className="total-display">
//                 <h3>RM {amountToPay} </h3>
//                 <h6>Cash Entered: RM {cashAmount || '0.00'}</h6>
//                 <h5>Balance: RM {calculateBalance() >= 0 ? calculateBalance().toFixed(2) : '0.00'}</h5>
//               </div>
//               <div className="numpad">
//                 <div className="numpad-row">
//                   <button onClick={() => handleCashInput('1')}>1</button>
//                   <button onClick={() => handleCashInput('2')}>2</button>
//                   <button onClick={() => handleCashInput('3')}>3</button>
//                   <button onClick={() => handleQuickAdd(10)}>+10</button>
//                 </div>
//                 <div className="numpad-row">
//                   <button onClick={() => handleCashInput('4')}>4</button>
//                   <button onClick={() => handleCashInput('5')}>5</button>
//                   <button onClick={() => handleCashInput('6')}>6</button>
//                   <button onClick={() => handleQuickAdd(20)}>+20</button>
//                 </div>
//                 <div className="numpad-row">
//                   <button onClick={() => handleCashInput('7')}>7</button>
//                   <button onClick={() => handleCashInput('8')}>8</button>
//                   <button onClick={() => handleCashInput('9')}>9</button>
//                   <button onClick={() => handleQuickAdd(50)}>+50</button>
//                 </div>
//                 <div className="numpad-row">
//                   <button onClick={() => handleCashInput('0')}>0</button>
//                   <button onClick={() => handleCashInput('.')}>.</button>
//                   <button onClick={clearCashInput}>Clear</button>
//                 </div>
//                 <div className="numpad-row">
//                   <button 
//                     onClick={submitPayment} // Call the submitPayment function
//                     className="pay-button"
//                   >
//                     Validate
//                   </button>
//                 </div>
//               </div>
//             </>
//           )}

//           {(paymentMethod === 'transfer2' || paymentMethod === 'transfer3' || paymentMethod === 'transfer4') && (
//             <div className="transaction-id-section">
//                 <h3>RM {amountToPay} </h3>
//               <h5>Enter Transaction ID:</h5>
//               <input
//                 type="text"
//                 value={transactionId}
//                 onChange={handleTransactionIdChange}
//                 placeholder="Transaction ID"
//               />
//               <button onClick={submitPayment}>Submit Transaction</button> {/* Call the submitPayment function */}
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PaymentPage;