import React, { useState, useEffect } from "react";
import tableImage from '../assets/images/chair.png';
import { Card, Modal, Button, Table, Collapse, message, Popconfirm } from 'antd';
import axios from 'axios';
import { DeleteOutlined, CheckOutlined } from "@ant-design/icons";
import './BillList.css';
import PaymentPage from "../pages/PaymentPage";
import Receipt from '../components/Receipt';
import PreviewReceipt from '../components/PreviewReceipt';
import './TableList.css';
import { getIsValidated } from '../utils/paymentData';
import { useValidation } from '../contexts/ValidationContext';
import CloseButton from "../components/CloseButton";

const TableList = ({ table }) => {
    const { Meta } = Card;
    // const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [orders, setOrders] = useState([]);
    const [tableNumber, setTableNumber] = useState(table.table_number);
    const [tableOrders, setTableOrders] = useState({});
    const [showReceiptModal, setShowReceiptModal] = useState(false);
    const [showPreviewReceiptModal, setShowPreviewReceiptModal] = useState(false);
    const [selectedOrderId, setSelectedOrderId] = useState(null); // New state for the selected order ID
    const [taxes, setTaxes] = useState([]);
    const [isPaymentValidated, setIsPaymentValidated] = useState(false); // handle show paid receipt button if Ok button is not selected
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    // const [selectedOrders, setSelectedOrders] = useState([]);
    // const { isValidated, selectedTable } = getIsValidated();
    const { isValidated, selectedTable } = useValidation();
    const [isPaymentButtonTapped, setIsPaymentButtonTapped] = useState(false);
    const [selectedOrders, setSelectedOrders] = useState(() => {
        const storedValue = localStorage.getItem('selectedOrders');
        return storedValue ? JSON.parse(storedValue) : [];
    });

    useEffect(() => {
        localStorage.setItem('selectedOrders', JSON.stringify(selectedOrders));
      }, [selectedOrders]);
    


    const onChange = (key) => {
        console.log(key);
    };

    const getAllOrders = async () => {
        try {
          const { data } = await axios.get("/api/orders/get-order");
          setOrders(data);
        } catch (error) {
          console.log(error);
        }
      };

    useEffect(() => {
        const fetchTableOrders = async () => {
            try {
                const { data: ordersData } = await axios.get(`/api/orders/get-bill?table=${tableNumber}`);
                console.log('API Response:', ordersData);  
                const tableOrdersMap = {};
                ordersData.forEach(order => {
                    const { order_tablenumber } = order;
                    if (!tableOrdersMap[order_tablenumber]) {
                        tableOrdersMap[order_tablenumber] = [];
                    }
                    tableOrdersMap[order_tablenumber].push(order);
                });

                console.log('Processed Table Orders:', tableOrdersMap);
                setTableOrders(tableOrdersMap);
            } catch (error) {
                console.log('Error fetching orders:', error);
            }
        };
        fetchTaxes();
        fetchTableOrders();
    }, [tableNumber]);

    const fetchData = async (tableNumber) => {
        try {
            const ordersData = tableOrders[tableNumber] || [];
            console.log('Orders data in fetchData:', ordersData); 
            setOrders(ordersData);
        } catch (error) {
            console.log('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData(tableNumber);
        console.log('Orders after fetching:', orders);
    }, [tableNumber, tableOrders]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const paramTableNumber = urlParams.get('table');

        if (paramTableNumber && paramTableNumber === String(tableNumber)) {
            setOpen(true);
        }
    }, [tableNumber]);

    const handleDeleteOrder = async (record) => {
    try {
      await axios.post("/api/orders/delete-order", { orderArrayId: record._id });
      message.success('Order Deleted Successfully')
      getAllOrders();
    } catch (error) {
      message.error('Something Went Wrong')
      console.log(error);
    }
  }

    const handleCancel = () => {
        setOpen(false);
    };

    const handleOk = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setOpen(false);
        }, 3000);
    };

    // const handleOkClick = async () => {
    //     try {
    //       const uniqueOids = [...new Set(orders.map(order => order.oid))];
      
    //       // Create an array of orders to be updated
    //       const ordersToUpdate = uniqueOids.map(oid => ({
    //         orderId: oid,
    //         order_progressstatus: "Paid"
    //       }));
      
    //       // Send a single request with the orders array
    //       const response = await axios.put('http://localhost:8080/api/orders/edit-order', {
    //         orders: ordersToUpdate
    //       });
      
    //       if (response.status === 200) {
    //         // alert('All orders updated to Paid!');
    //       } else {
    //         console.error('Some orders failed to update');
    //       }
    //     } catch (error) {
    //       console.error('Error:', error);
    //     }
    //   };

    const handleOkClick = async () => {
    try {
        // Create an array of orders to be updated based on selectedOrders
        const ordersToUpdate = selectedOrders.map(oid => ({
            orderId: oid,
            order_progressstatus: "Paid"
        }));

        // Iterate over each order and update its status
        for (const order of ordersToUpdate) {
            const response = await axios.put('/api/orders/edit-order', {
                orders: [order] // Send one order at a time
            });

            if (response.status !== 200) {
                console.error(`Failed to update order ID ${order.orderId}`);
            }
        }

        message.success('Selected orders updated to Paid!');
    } catch (error) {
        console.error('Error:', error);
        message.error('Failed to update orders.');
    }
};
           

    const fetchTaxes = async () => {
        try {
          const response = await axios.get('/api/taxes/get-taxes');
          setTaxes(response.data);  // Assuming the API returns an array of tax objects
        } catch (error) {
          console.error("Error fetching taxes:", error);
          message.error("Failed to fetch taxes.");
        }
    };

    const { Panel } = Collapse;

    const handleShowReceipt = (orderId) => {
        setSelectedOrderId(orderId); // Set the selected order ID when showing the receipt
        setShowReceiptModal(true);
    };

    const handleShowPreviewReceipt = (orderId) => {
      setSelectedOrderId(orderId); // Set the selected order ID when showing the receipt
      setShowPreviewReceiptModal(true);
  };

    // const calculateTotalWithTaxes = (order) => {
    //     const orderItems = orders.flatMap(order => order.result);
    //     const totalWithoutTax = orderItems.reduce(
    //         (acc, curr) => acc + curr.orderArray_price * curr.orderArray_quantity,
    //         0
    //     );
    //     const totalTax = taxes.reduce((acc, tax) => acc + totalWithoutTax * (tax.rate / 100), 0);
    //     return (totalWithoutTax + totalTax).toFixed(2);
    // };

    // const calculateTotalWithoutTaxes = (order) => {
    //     const orderItems = orders.flatMap(order => order.result);
    //     const totalWithoutTax = orderItems.reduce(
    //         (acc, curr) => acc + curr.orderArray_price * curr.orderArray_quantity,
    //         0
    //     );

    //     return (totalWithoutTax).toFixed(2);
    // };

    const calculateTotalWithTaxes = (order) => {
        const totalWithoutTax = (order.result || []).reduce(
            (acc, curr) => acc + curr.orderArray_price * curr.orderArray_quantity,
            0
        );
        const totalTax = taxes.reduce((acc, tax) => acc + totalWithoutTax * (tax.rate / 100), 0);
        return (totalWithoutTax + totalTax).toFixed(2);
    };
    
    const calculateTotalWithoutTaxes = (order) => {
        const totalWithoutTax = (order.result || []).reduce(
            (acc, curr) => acc + curr.orderArray_price * curr.orderArray_quantity,
            0
        );
        return totalWithoutTax.toFixed(2);
    };
    

    // Calculate aggregated totals for selected orders
  const getTotalWithTaxes = () => {
    return orders
      .filter((order) => selectedOrders.includes(order.oid)) // Only selected orders
      .reduce((total, order) => total + parseFloat(calculateTotalWithTaxes(order)), 0)
      .toFixed(2); // Format to 2 decimal places
  };

  const getTotalWithoutTaxes = () => {
    return orders
      .filter((order) => selectedOrders.includes(order.oid)) // Only selected orders
      .reduce((total, order) => total + parseFloat(calculateTotalWithoutTaxes(order)), 0)
      .toFixed(2); // Format to 2 decimal places
  };

  const getTotalTaxes = () => {
    return orders
      .filter((order) => selectedOrders.includes(order.oid)) // Only selected orders
      .reduce((total, order) => {
        const totalWithTaxes = parseFloat(calculateTotalWithTaxes(order));
        const totalWithoutTaxes = parseFloat(calculateTotalWithoutTaxes(order));
        return total + (totalWithTaxes - totalWithoutTaxes); // Add the tax difference
      }, 0)
      .toFixed(2); // Format to 2 decimal places
  };


  // Initialize selected orders with all orders when the component mounts
  useEffect(() => {
    setSelectedOrders(orders.map((order) => order.oid)); // Set all order IDs as selected
  }, [orders]); // Re-run when orders data changes


    const handlePaymentValidation = async (paymentData) => {
        try {
            // 1. Send payment data to the payment API
            const response = await axios.post('/api/payments', paymentData);
            
            if (response.status === 201) {
                console.log('Payment successful:', response.data);

                await handleOkClick(); // This function already updates order status

                setShowPaymentModal(false); // Close payment modal
                setShowReceiptModal(true); // Show receipt modal
                setIsPaymentButtonTapped(false);
                setIsPaymentValidated(true);
            } else {
                alert('Payment failed. Please try again.');
            }
        } catch (error) {
            console.error('Error during payment validation:', error);
            alert('An error occurred while processing the payment.');
        }
    };

    const printReceipt = () => {
    // Print receipt logic or open receipt modal
    setShowReceiptModal(true);
    // After showing the receipt, you might want to print it or handle it accordingly
    };

  // Handle checkbox change
  const handleCheckboxChange = (event, orderId) => {
    if (event.target.checked) {
      // Add order to selected list
      setSelectedOrders((prev) => [...prev, orderId]);
    } else {
      // Remove order from selected list
      setSelectedOrders((prev) => prev.filter((id) => id !== orderId));
    }
  };  

  const showModal = () => {
    fetchTaxes();  // Fetch taxes when opening the modal
    setOpen(true);
  };

  const getTotalItems = () => {
    return orders.reduce((total, order) => {
      if (Array.isArray(order.result)) {
        return total + order.result.reduce((acc, curr) => {
          return acc + (curr.orderArray_quantity || 0); // Default to 0 if undefined
        }, 0);
      }
      return total; // If order.result is not an array, return the current total
    }, 0).toFixed(0);
  };
  const totalItems = getTotalItems();

    return (
        <div>
            <div className="card-size">
            {/* <Card
                hoverable
                style={{
                  width: 230,
                  marginBottom: 20,
                  borderRadius: 8, // Rounded corners for a softer look
                  backgroundColor: tableOrders[table.table_number] &&
                                  tableOrders[table.table_number].some(order => order.order_status)
                                  ? 'rgba(255, 0, 0, 0.2)' // Apply red background if table is busy
                                  : 'rgba(153, 255, 153, 0.2)', // Default background when available
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Smooth hover transition
                }}
                cover={<img alt={table.table_number} src={tableImage} style={{ borderTopLeftRadius: 8, borderTopRightRadius: 8 }} />}
                onClick={() => showModal(table.table_number)}
                onMouseEnter={(e) => e.target.style.transform = 'scale(1.05)'} // Slight zoom effect on hover
                onMouseLeave={(e) => e.target.style.transform = 'scale(1)'} // Reset zoom when mouse leaves
              > */}

                <Card
                hoverable
                style={{
                    marginBottom: 20,
                    borderRadius: 8, // Rounded corners for a softer look
                    backgroundColor: table.table_status ? 
                        (tableOrders[table.table_number] && tableOrders[table.table_number].some(order => order.order_status)
                            ? 'rgba(255, 0, 0, 0.2)' // Apply red background if table is busy
                            : 'rgba(153, 255, 153, 0.2)') // Default background when available
                        : 'rgba(200, 200, 200, 0.5)', // Grey when not available
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
                    transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Smooth hover transition
                    position: 'relative', // Add this to make the absolute positioning work
                }}
                cover={
                    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                    <img alt={table.table_number} src={tableImage} style={{ width: '100%', height: '100%', objectFit: 'cover', borderTopLeftRadius: 8, borderTopRightRadius: 8 }} />
                    <div style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)', // Move the element 50% to the left and top of its own width and height
                        fontSize: 24,
                        fontWeight: 'bold',
                        color: 'white',
                        zIndex: 1,
                        // backgroundColor: 'rgba(0, 0, 0, 0.5)', // Add a semi-transparent black background
                        backgroundColor: table.table_status ? 
                            (tableOrders[table.table_number] && tableOrders[table.table_number].some(order => order.order_status)
                                    ? "rgb(210, 4, 45)"//'rgba(255, 0, 0, 0.2)' // Apply red background if table is busy
                                    : "rgb(50, 205, 50)")//'rgba(153, 255, 153, 0.2)', // Default background when available
                                :'rgba(200, 200, 200)',
                        borderRadius: '50%', // Make it a circle
                        padding: '12px 25px', // Add some padding to make it look better
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        {table.table_number}
                    </div>
                    </div>
                }
                onClick={table.table_status ? () => showModal(table.table_number) : null}
                //   onMouseEnter={(e) => e.target.style.transform = 'scale(1.05)'} // Slight zoom effect on hover
                //   onMouseLeave={(e) => e.target.style.transform = 'scale(1)'} // Reset zoom when mouse leaves
                >

                <Meta
                    title={`Table ${table.table_number}`}
                    description={
                        <span
                        style={{
                            color: table.table_status ? 
                                (tableOrders[table.table_number] && tableOrders[table.table_number].some(order => order.order_status)
                                ? "red"
                                : "green")
                                : "grey",
                            fontWeight: "bold",
                            fontSize: '14px', // Slightly smaller font size for the description
                            display: 'block',
                            marginTop: '5px',
                        }}
                        >
                        {table.table_status ? 
                            (tableOrders[table.table_number] && tableOrders[table.table_number].some(order => order.order_status)
                                ? "Busy"
                                : "Available")
                            : "Unavailable"}
            </span>
          }
        />
        {totalItems > 0 && (
            <div style={{
                position: 'absolute',
                bottom: 20, // Adjust as needed
                right: 20, // Adjust as needed
                backgroundColor: 'rgba(0, 0, 0, 0.7)', // Semi-transparent background for better visibility
                color: 'white',
                width: 30, // Set a fixed width
                height: 30, // Set a fixed height
                borderRadius: '50%', // Make it a circle
                display: 'flex', // Use flexbox to center the text
                justifyContent: 'center', // Center horizontally
                alignItems: 'center', // Center vertically
                textAlign: 'center',
                fontSize: 16,
                zIndex: 2, // Ensure it appears above other elements
            }}>
                {totalItems}
            </div>
          )}
            </Card>
            </div>
            
            <Modal
                open={open}
                title={
                <div>
                {/* <button
                  type="button"
                  aria-label="Close"
                  className="ant-modal-close"
                  onClick={handleCancel} // Function to close the modal
                >
                  <span className="ant-modal-close-x">
                    <span
                      role="img"
                      aria-label="close"
                      className="anticon anticon-close ant-modal-close-icon"
                    >
                      <svg
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="close"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                      </svg>
                    </span>
                  </span>
                </button> */}
                <CloseButton onClick={handleCancel} />
                {`Table ${tableNumber}`}
              </div>}
                onOk={handleOk}
                onCancel={handleCancel}
                className="fixed-footer"
                width={1000}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Back
                    </Button>,
                    <Button
                    key="payment"
                    type="primary"
                    onClick={() => {
                        setIsPaymentButtonTapped(true);
                        setShowPaymentModal(true);
                    }}
                    disabled={selectedOrders.length === 0}
                >
                    Payment
                </Button>,
                    <Button
                        key="show-receipt"
                        type="primary"
                        loading={loading}
                        onClick={() => handleShowReceipt(orders[0]?._id)} // Pass the order ID
                        // disabled={orders.length === 0}
                        // disabled={!selectedTable || !isValidated || orders.length === 0}
                        disabled={isPaymentButtonTapped || !isValidated || selectedTable !== table.table_number || orders.length === 0 || !isPaymentValidated}
                    >
                        Show Paid Receipt
                    </Button>,
                    <Button
                    key="preview-receipt"
                    type="primary"
                    loading={loading}
                    onClick={() => handleShowPreviewReceipt(orders[0]?._id)} // Pass the order ID
                    // disabled={orders.length === 0}
                    // disabled={!selectedTable || !isValidated || orders.length === 0}
                    disabled={selectedOrders.length === 0}
                >
                    Preview Receipt
                </Button>,
                ]}
            >
                        <div style={{ marginBottom: 16 }}>
          <Button
            type="default"
            onClick={() => {
              const allOrders = orders.map(order => order.oid);
              setSelectedOrders(allOrders);
            }}
            disabled={selectedOrders.length === orders.length}
          >
            Select All
          </Button>
          <Button
            type="default"
            onClick={() => {
              setSelectedOrders([]);
            }}
            disabled={selectedOrders.length === 0}
            style={{ marginLeft: 8 }}
          >
            Deselect All
          </Button>
        </div>
                <Collapse         
                    defaultActiveKey={orders.map((order) => order.oid)} 
                    onChange={onChange}
                    collapsible="icon"
                    >
                        
                        {/* {orders.map((order) => ( */}
                        {orders.map((order) => (
                          order.order_progressstatus !== "Paid" && (   
                            // <Panel header={<strong>Order ID: #{order.oid}</strong>} key={order.oid}> 
                            <Panel
                                header={
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                    type="checkbox"
                                    checked={selectedOrders.includes(order.oid)}
                                    onChange={(e) => {
                                        handleCheckboxChange(e, order.oid)} // Add your handler
                                    } 
                                    style={{ marginRight: '8px' }} // Optional spacing

                                    />
                                    <strong>Order ID: #{order.oid}</strong>
                                </div>
                                }
                                key={order.oid}
                            >
                            <Table
                                bordered
                                pagination={false}
                                columns={[
                                    {
                                        title: 'No',
                                        key: 'index',
                                        render: (text, record, recordIndex) => (
                                            <span>{recordIndex + 1}</span>
                                        ),
                                    },
                                    {
                                        title: "Food Name",
                                        dataIndex: "orderArray_productName",
                                        render: (orderArray_productName) => orderArray_productName,

                                    },
                                    {
                                        title: "Price(RM)",
                                        dataIndex: "orderArray_price",
                                        // render: (orderArray_price) => {
                                        //     const formattedPrice = orderArray_price.toLocaleString("en-MY", {
                                        //         minimumFractionDigits: 2,
                                        //         maximumFractionDigits: 2,
                                        //     });
                                        //     return `RM${formattedPrice}`;
                                        // },
                                        render: (orderArray_price) => `RM${parseFloat(orderArray_price).toFixed(2)}`,

                                    },
                                    {
                                        title: "Quantity",
                                        dataIndex: "orderArray_quantity",
                                        render: (orderArray_quantity) => orderArray_quantity,
                                    },
                                    {
                                        title: "Total Price(RM)",
                                        render: (_, record) => {
                                            const totalPrice = (record.orderArray_price * record.orderArray_quantity).toFixed(2);
                                            return `RM${totalPrice}`;
                                        },
                                    },
                                    // {
                                    //     title: "Actions",
                                    //     render: (_, record) => (
                                    //         <div>
                                    //             <CheckOutlined
                                    //                 style={{ cursor: "pointer" }}
                                    //                 onClick={() => handleShowReceipt(order._id)} // Use the correct order ID
                                    //             />
                                    //             <Popconfirm
                                    //                 title="Are you sure you want to delete this record?"
                                    //                 onConfirm={() => handleDeleteOrder(record)}
                                    //                 okText="Yes"
                                    //                 cancelText="No"
                                    //             >
                                    //                 <DeleteOutlined
                                    //                     style={{ cursor: "pointer", marginLeft: 8 }}
                                    //                 />
                                    //             </Popconfirm>
                                    //         </div>
                                    //     ),
                                    // },
                                ]}
                                dataSource={order.result || []}
                            />
                            <div style={{ textAlign: "right", marginTop: "1rem", fontSize: "1.3em" }}>
                                Total Price: RM{" "}
                                <span style={{ fontWeight: "bold", fontSize: "1.1em" }}>
                                {calculateTotalWithoutTaxes(order)}
                                </span>
                            </div>
                
                            <div style={{ textAlign: "right", fontSize: "1.3em" }}>
                                Total Tax: RM{" "}
                                <span style={{ fontWeight: "bold", fontSize: "1.1em" }}>
                                {(calculateTotalWithTaxes(order) - calculateTotalWithoutTaxes(order)).toFixed(2)}
                                </span>
                            </div>
                
                            <div style={{ textAlign: "right", marginTop: "1rem", fontSize: "1.5em" }}>
                                Total Price with Taxes: RM{" "}
                                <span style={{ fontWeight: "bold", fontSize: "1.3em" }}>
                                {calculateTotalWithTaxes(order)}
                                </span>
                            </div>
                        </Panel>
                        )
                    ))}
                </Collapse>
                <>
                    <style>{`
                    .totals-section {
                        margin-top: 50px;
                        margin-right: 20px;
                        font-size: 1.0em;
                        text-align: right;
                    }
                    `}</style>
                    
                    {/* Display totals */}
                    <div className="totals-section">
                    <p>
                        Total for Selected Orders (Without Taxes):  
                        <strong style={{ fontSize: "1.5em" }}> RM {getTotalWithoutTaxes()}</strong>
                    </p>

                    <p>
                        Total Tax for Selected Orders:  
                        <strong style={{ fontSize: "1.5em" }}> RM {getTotalTaxes()}</strong>
                    </p>

                    <p style={{ fontSize: "1.2em" }}>
                        Total for Selected Orders (With Taxes): 
                        <strong style={{ fontSize: "1.8em" }}> RM {getTotalWithTaxes()}</strong>
                    </p>
                    </div>
                </>
            </Modal>

            <Modal
                // title="Receipt"
                title={
                  <div>
                  Receipt
                  <CloseButton className="close-button" onClick={() => {
                    setTimeout(() => {
                        setShowReceiptModal(false);
                        window.location.reload();
                      }, 500); 
                }} />
                  </div>
                }
                open={showReceiptModal}
                onCancel={() => {
                    setTimeout(() => {
                        setShowReceiptModal(false);
                        window.location.reload();
                      }, 500); 
                }}
                onOk={() => {
                    window.print();
                    setTimeout(() => {
                        setShowReceiptModal(false);
                        window.location.reload();
                      }, 500); 
                }}
                className="print-modal"
                maskClosable={false}
            >
               <Receipt
                    orders={orders}
                    taxes={taxes || []} // Provide a default empty array if `taxes` is undefined
                    tableNumber={tableNumber}
                    selectedOrders={selectedOrders}
                    
                />
            </Modal>

            <Modal
                // title="Preview Receipt"
                title={
                  <div>
                  Preview Receipt
                  <CloseButton className="close-button" onClick={() => setShowPreviewReceiptModal(false)} />
                  </div>
                }
                open={showPreviewReceiptModal}
                onCancel={() => setShowPreviewReceiptModal(false)}
                onOk={() => {
                  window.print();
                  setTimeout(() => {
                    setShowPreviewReceiptModal(false);
                    }, 500); 
              }}
                className="print-modal"
            >
               <PreviewReceipt
                    orders={orders}
                    taxes={taxes || []} // Provide a default empty array if `taxes` is undefined
                    tableNumber={tableNumber}
                    selectedOrders={selectedOrders}
                />
            </Modal>


            <Modal
                // title="Payment"
                title={
                  <div>
                  Payment
                  <CloseButton onClick={() => setShowPaymentModal(false)} />
                  </div>
                }
                open={showPaymentModal}
                onCancel={() => setShowPaymentModal(false)}
                footer={null}
            >
                <PaymentPage
                    tableNumber={tableNumber}
                    totalWithTaxes={getTotalWithTaxes()}
                    orders={orders.flatMap(order => order.result)}
                    onPaymentValidation={handlePaymentValidation} // Pass the validation function
                />
            </Modal>

            
        </div>
    );
};

export default TableList;
