import React, { useEffect, useState } from 'react';
import DefaultLayout from '../components/DefaultLayout';
import { Modal, Button, Form, Input, Select, message, Table, Space } from "antd";
import { EyeOutlined, EditOutlined, DeleteOutlined }
  from '@ant-design/icons';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextArea from 'antd/lib/input/TextArea';
import axios from 'axios';
import { Products } from 'antd';
import CloseButton from "../components/CloseButton";

const SetMenu = () => {

  const [popupModal, setPopupModal] = useState(false);
  const [buttonText, setButtonText] = useState('Add');
  const [searchText, setSearchText] = useState("");
  const [editMenu, setEditMenu] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [top, setTop] = useState('topRight');
  const [bottom, setBottom] = useState('bottomRight');
  let [filteredData] = useState();


  const apiEndPoint = "http://localhost:3000/api/products";


  const getTable = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(apiEndPoint);
      getTable(data);
      setGridData(data);
      setLoading(false);
      return data.map((numberData) => {
        return numberData.name;
      });
    }
    catch (error) {
      console.log(error);
    }
  };

  const data = [
    {
      key: '1',
      name: '肉骨茶 Signature Bakutehh',
      description: "a pork rib dish cooked in broth popularly served in Malaysia where there is a predominant Hoklo and Teochew community.",
      category: "main dish",
      status: "Available",
      price: '8.00',
    },
    {
      key: '2',
      name: '排骨支1 Spare Ribs Bakuteh',
      description: "Braised spare ribs.",
      category: "main dish",
      status: "Available",
      price: '8.00',
    },
    {
      key: '3',
      name: 'Fish Fillet',
      description: "Boneless fish fillet.",
      category: "side dish",
      status: "Not available",
      price: '8.00',
    },
    {
      key: '4',
      name: '100 Plus',
      description: "",
      category: "beverage",
      status: "Available",
      price: '2.00',
    },
    {
      key: '5',
      name: '可口可乐 Coca-Cola',
      description: "",
      category: "beverage",
      status: "Available",
      price: '2.00',
    },
    {
      key: '6',
      name: '菊花茶 Chrysanthemum Tea',
      description: "",
      category: "beverage",
      status: "Available",
      price: '2.00',
    },
  ];

  const columns = [
    {
      title: "#",
      width: 60,
      align: "center",
      key: 'index',
      render: (value, item, index) => page === 1 ? (page - 1) * 10 + (++index) : (page - 1) * 10 + (++index),
    },
    {
      title: "Name",
      dataIndex: "name",
      align: "center",
      editTable: true,
    },
    {
      title: "Description",
      dataIndex: "description",
      align: "center",
      editTable: true,
      width: '30%',
    },
    {
      title: "Category",
      dataIndex: "category",
      align: "center",
      editTable: true,
      filters: [
        {
          text: 'main dish',
          value: 'main dish',
        },
        {
          text: 'side dish',
          value: 'side dish',
        },
        {
          text: 'beverage',
          value: 'beverage',
        },
      ],
      filterSearch: true,
      onFilter: (value, record) => record.category.startsWith(value),
      width: '15%',
    },
    {
      title: "Price (RM)",
      dataIndex: "price",
      align: "center",
      editTable: true,
      width: '10%',
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      editTable: true,
      filters: [
        {
          text: 'Available',
          value: 'Available',
        },
        {
          text: 'Not available',
          value: 'Not available',
        },
      ],
      filterSearch: true,
      onFilter: (value, record) => record.status.startsWith(value),
      width: '20%',
    },
    {
      title: 'Action',
      key: 'action',
      width: 70,
      render: (_, item) => (
        <Space size="middle">
          <EditOutlined onClick={() => { setPopupModal(item); setButtonText('Update'); }}></EditOutlined>
          <DeleteOutlined onClick={() => { setShowConfirm(true) }}></DeleteOutlined>
        </Space>
      ),
    },
  ]


  const onFinish = (values) => {
    console.log('Success:', values);

  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleDelete = async (tax) => {
    message.success('Data Deleted Succesfully');
    setShowConfirm(false);
  }

  const handleNo = () => {
    setShowConfirm(false);
  };

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };

  const reset = () => {
    setSearchText("");

  }

  const handleInputChange = (e) => {
    setSearchText(e.target.value);
    if (e.target.value === "") {
    }
  };

  const globalSearch = () => {
    filteredData = data.filter((value) => {
      return (
        value.name.toLowerCase().includes(searchText.toLowerCase())
        // value.table_link.toLowerCase().includes(searchText.toLowerCase()) ||
        // value.table_qr.toLowerCase().includes(searchText.toLowerCase()) 
      );
    });
    setGridData(filteredData);
    setPage(1);
  }



  return (
    <DefaultLayout>
      <div className="d-flex justify-content-between">
        <h1>Menu</h1>
        <Button type="primary" onClick={() => { setPopupModal(true); setButtonText('Add'); setEditMenu(null) }}>
          Add Menu
        </Button>
      </div>

      {
        popupModal && (
          <Modal
            // title={`${editMenu !== null ? "Edit menu item" : "Add menu item"}`}
            title={
              <div>
                  {editMenu !== null ? 'Edit menu item' : 'Add menu item'}
                  <CloseButton onClick={() => setPopupModal(false)} />
              </div>
            }
            visible={popupModal}
            onCancel={() => {
              setPopupModal(false);

            }}
            footer={false}>

            <Form
              name="basic"
              labelCol={{
                span: 6,

              }}
              wrapperCol={{
                span: 16,
              }}
              labelAlign="left"
              //   initialValues={editMenu}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}>

              <Form.Item
                label="Name"
                name="Name"
                rules={[{ required: true, message: 'Name is required' }]}
              >
                <Input required
                  type="string"
                  onChange={(e) => { }}
                />
              </Form.Item>
              <Form.Item
                label="Description"
                name="description"
              >
                <TextArea required
                  placeholder={`Enter description here...`}
                  onChange={(e) => { }} />
              </Form.Item>
              <Form.Item name="image" label="Image URL"
                rules={[{ required: true, message: 'Image url is required' }]}>
                <Input />
              </Form.Item>
              <Form.Item name="category" label="Category"
                rules={[{ required: true, message: 'Category is required' }]}>
                <select onChange={(e) => { }} style={{ height: 30 }} >
                  <option>--Select category--</option>
                  <option value="mainDish">
                    Main dish</option>
                  <option value="sideDish">Side Dish
                  </option>
                  <option value="beverage">Beverage
                  </option>
                </select>
              </Form.Item>
              <Form.Item
                label="Status"
                name="status"
                rules={[{ required: true, message: 'Status is required' }]}>
                <select onChange={(e) => { }} style={{ height: 30 }} >
                  <option>--Select status--</option>
                  <option selected value="available">Available</option>
                  <option value="notAvailable">
                    Not available
                  </option>
                </select>
              </Form.Item>
              <Form.Item
                label="Price"
                name="price"
                rules={[{ required: true, message: 'Price is required' }]}>
                <Input required
                  style={{ width: 70 }}
                  type="number"
                  defaultValue="0"
                  onChange={(e) => {
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Stock"
                name="stock"
                rules={[{ required: true, message: 'Stock is required' }]}>
                <Input required
                  style={{ width: 70 }}
                  type="number"
                  defaultValue="0"
                  onChange={(e) => {
                  }}
                />
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <div className="d-flex justify-content-end">
                  <Button type="primary" htmlType="submit">
                    {buttonText}
                  </Button></div>
              </Form.Item>
            </Form>

          </Modal>
        )
      }

      <div>

        <Space style={{ marginBottom: 16 }}>
          <Input placeholder="Enter search text"
            onChange={handleInputChange}
            type="text"
            allowClear
            value={searchText}
          />

          <Button onClick={globalSearch} type="primary">Search</Button>
          <Button onClick={reset} type="primary">Reset</Button>
        </Space>
        <Table

          columns={columns}
          dataSource={data}
          bordered
          loading={loading}
          onChange={onChange}
          pagination={{
            position: [top, bottom],
            total: data.length,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            showQuickJumper: true,
            showSizeChanger: false,
            onChange(current) {
              setPage(current);
            }
          }}
        >


        </Table>

        {showConfirm && (
          <div>
            <Dialog
              open={showConfirm}
              onClose={handleNo}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Confirm Delete"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure to delete
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button type="primary" danger
                  onClick={() => { handleDelete() }}
                  className="btn btn-danger btn-sm"
                >
                  Yes
                </Button>
                <Button type="primary" style={{
                  background: "#C2C2CA",
                  borderColor: "#C2C2CA"
                }}
                  onClick={handleNo}
                >
                  No
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        )
        }



      </div>
    </DefaultLayout>
  )
};


export default SetMenu