let paymentData = {
    cashAmount: 0.00,
    finalTotal: 0.00, 
    discountValue: 0.00,
};

let validationData = {
    isValidated: false,
    selectedTable: 0,
};

export const setPaymentData = (cash, totalBeforeDiscount, totalAfterDiscount) => {
    paymentData.cashAmount = cash ? parseFloat(cash) : 0.0;
    paymentData.finalTotal = (Math.round(totalAfterDiscount * 20) / 20).toFixed(2);
    paymentData.discountValue = parseFloat(totalBeforeDiscount - totalAfterDiscount).toFixed(2);
};

export const getPaymentData = () => {
    return paymentData;
};

export const setIsValidated = (validation, table) => {
    validationData.isValidated = validation;
    validationData.selectedTable = table;
}

export const getIsValidated = () => {
    return validationData;
};


