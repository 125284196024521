import { children, createContext, useContext, useState } from 'react';

const ValidationContext = createContext();

export const useValidation = () => useContext(ValidationContext);

export const ValidationProvider = ({ children }) => {
const [isValidated, setIsValidated] = useState(false);
const [selectedTable, setSelectedTable] = useState(0);

const updateValidation = (validation, table) => {
    setIsValidated(validation);
    setSelectedTable(table);
};

return (
    <ValidationContext.Provider value={{ isValidated, selectedTable, updateValidation }}>
    {children}
    </ValidationContext.Provider>
);
};
