import React, {useEffect, useState} from 'react';
import DefaultLayout from '../components/DefaultLayout';
import {
    DeleteOutlined, EditOutlined, CaretUpOutlined, CaretDownOutlined
} from "@ant-design/icons";
import axios from 'axios';
import { Table, Button, Modal, Form, Input, Select, message, Popconfirm } from 'antd';
import CloseButton from "../components/CloseButton";
//import { Footer } from 'antd/lib/layout/layout';
//import FormItem from 'antd/es/form/FormItem';


const Staff = () => {
    //const dispatch = useDispatch();
    const [staffData, setStaffData] = useState([]);
    const [popupModal, setPopupModal] = useState(false);
    const [editStaff, setEditStaff] = useState(null);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [roleOpen, setRoleOpen] = useState(false);

    const handleRoleDropdownVisibleChange = (open) => {
      setRoleOpen(open);
    };

    //useEffecta
    const getALLStaff = async () => {
            try {
                //      dispatch({
                //        type: 'SHOW_LOADING',
                //      });
                      const {data} = await axios.get('/api/users/get-user');
                      setStaffData(data);
                //      dispatch({type: 'HIDE_LOADING'});
                      console.log(data);
                    } catch (error) {
                      console.log(error);
                    }
                  };
    useEffect(() => {
                    getALLStaff();
    }, []);

    //handle delete
    const handledDelete = async (record) => {
      try {
        await axios.post("/api/users/delete-staff", {usersId:record._id});
        
        message.success("Delete successful");
        getALLStaff();
        setPopupModal(false);
      } catch (error) {
        message.error("Delete failed");
        console.log(error); 
      }
    };
// search
    const handleSearch = (e) => {
      setSearchKeyword(e.target.value);
    }

    const filteredData = staffData.filter(record => {
      const nameMatch = record.name.toLowerCase().includes(searchKeyword.toLowerCase());
      const userIdMatch = record.userId.toLowerCase().includes(searchKeyword.toLowerCase());
      const roleMatch = record.role.toLowerCase().includes(searchKeyword.toLowerCase());
      return nameMatch || userIdMatch || roleMatch;
    });

    //table data
    const columns = [
        {title: "Name", dataIndex: "name", key: "name"},
        {title: "ID", dataIndex: "userId", key: "userId"},
        {title: "Role", dataIndex: "role", key: "role"},
        {
         title: "Action",
         dataIndex: "_id",
         render: (id, record) => (
          <div>
            <EditOutlined 
            style={{cursor : "pointer"}}
            onClick={() => {
                setEditStaff(record);
                setPopupModal(true);
            }}
            />
            <Popconfirm
            title="Are you sure you want to delete this record?"
            onConfirm={() => handledDelete(record)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined
              style={{ cursor: "pointer", marginLeft: 8 }}
            />
          </Popconfirm>
            

          </div>
            
        ),
    },
    ];
    
    const handleSubmit = async (values) => {
      const { password, ...otherValues } = values;
      const data = { ...otherValues, password }; // Send plain text password
  
      if (editStaff === null) {
          try {
              await axios.post("/api/users/register", data);
              message.success("Register successful");
              getALLStaff();
              setPopupModal(false);
          } catch (error) {
              message.error("Register failed");
              console.log(error);
          }
      } else {
          try {
              await axios.put("/api/users/edit-staff", {
                  ...data,
                  usersId: editStaff._id
              });
              message.success("Updated successful");
              getALLStaff();
              setPopupModal(false);
          } catch (error) {
              message.error("Updated failed");
              console.log(error);
          }
      }
  };


  return (
    <DefaultLayout>
      <div className="d-flex justify-content-between">
        <h1>Staff Members List</h1>
        <Button type="primary"  onClick={() => setPopupModal(true)}>
          Add New Staff
          </Button>
          
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
      <Input.Search
           placeholder="Search..."
           value={searchKeyword}
           onChange={handleSearch}
           size="large"
           style={{ width: 300 }}
      />
      </div>
        <Table columns={columns} dataSource={filteredData} bordered/>
        {
            popupModal && (
          <Modal
            // title={`${editStaff !== null ? "Edit Staff" : "Add New Staff"}`}
            title={
              <div>
                  {editStaff !== null ? 'Edit Staff' : 'Add New Staff'}
                  <CloseButton onClick={() => { setEditStaff(null); setPopupModal(false); }} />
              </div>
            }
            open={popupModal}
            onCancel={() => {
              setEditStaff(null);
              setPopupModal(false);

            }}
            footer={false}
          >
            <Form layout="vertical"
              initialValues={editStaff}
              onFinish={handleSubmit}>
              <Form.Item name="name" label="Name"
                rules={[
                  { required: true, message: 'Please enter the name' },
                  { min: 3, message: 'Name must be at least 3 characters' },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item name="userId" label="User ID"
                rules={[
                  { required: true, message: 'Please enter Staff ID' },
                  { min: 3, message: 'ID must be at least 3 characters' },
                ]}>
                <Input />
              </Form.Item>
              {editStaff !== null ? null : (
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    { required: true, message: 'Please enter your password' },
                    { min: 6, message: 'Password must be at least 6 characters long' },
                    { pattern: /[A-Z]/, message: 'Password must include at least one uppercase letter' },
                    { pattern: /[a-z]/, message: 'Password must include at least one lowercase letter' },
                    { pattern: /[0-9]/, message: 'Password must include at least one number' },
                    { pattern: /[!@#$%^&*]/, message: 'Password must include at least one special character (!@#$%^&*)' },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              )}
              {/* <Form.Item name="role" label="Role"
                rules={[
                  { required: true, message: 'Please select role' },
                ]}>
                <Select>
                  <Select.Option value="admin">Admin</Select.Option>
                  <Select.Option value="staff">Staff</Select.Option>
                </Select>
              </Form.Item> */}

              <Form.Item
                name="role"
                label="Role"
                rules={[{ required: true, message: 'Please select role' }]}
              >
                <Select
                  onDropdownVisibleChange={handleRoleDropdownVisibleChange } 
                  suffixIcon={roleOpen  ? <CaretUpOutlined /> : <CaretDownOutlined />} 
                >
                <Select.Option value="admin">Admin</Select.Option>
                <Select.Option value="staff">Staff</Select.Option>
                </Select>
              </Form.Item>
              <div className="d-flex justify-content-end ant-modal-footer">
                <Button type="primary" htmlType='submit'>Save</Button>
              </div>
            </Form>
          </Modal>)
        }
    </DefaultLayout>
  );
};


export default Staff;