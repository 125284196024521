import React, { useEffect, useState } from "react";
import DefaultLayout from '../components/DefaultLayout';
import { Modal, Button, Form, Input, message, Table, Space } from "antd";
import { EditOutlined, DeleteOutlined, QrcodeOutlined, DownloadOutlined } from '@ant-design/icons'
import axios from "axios";
import QRCode from "qrcode";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CloseButton from "../components/CloseButton";

const SetTable = () => {
  const [popupModal, setPopupModal] = useState(false);
  const [qrModalOpen, setQrModalOpen] = useState(false);

  const [tables, setTables] = useState([]);
  const [NewTable, setNewTable] = useState([]);
  const [buttonText, setButtonText] = useState('Add');
  const [tableNo, setTableNo] = useState("");
  const [Pax, setPax] = useState("");
  const [tableQr, setTableQr] = useState("");
  const [editTable, setEditTable] = useState([]);
  const [value, setValue] = useState('');
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [top, setTop] = useState('topRight');
  const [bottom, setBottom] = useState('bottomRight');

  const [gridData, setGridData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [sortedInfo, setSortedInfo] = useState({});
  const [page, setPage] = useState(1);
  const [dataLength, setItemLength] = useState("");
  let [filteredData] = useState();
  const [qr, setQr] = useState('');
  const [allQr, setAllQr] = useState();
  const [tableLinkQr, setTableLinkQr] = useState('');
  const [linkData, setLinkData] = useState([]);
  const [url, setUrl] = useState([]);
  const [tableNoData, setTableNoData] = useState([]);

  const apiEndPoint = "http://localhost:3000/api/settable";
  const link = 'https://localhost:3000?table=' + tableNo;

  const getTable = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(apiEndPoint);
      setTables(data);
      setGridData(data);
      setLoading(false);
      // console.log(tables);
      setItemLength(data.length);
      return data.map((numberData) => {
        return numberData.table_no;
      });
    }
    catch (error) {
      console.log(error);
    }
  };

  const fetchedData = gridData.map((item) => ({
    ...item,
  }));

  const modifiedData = fetchedData.map(({ body, ...item }) => ({
    ...item,
    key: item._id,
    tableNo: item.table_no,
    link: item.table_link,
    Pax: item.pax,
  }));

  const GenerateQRCode = (link) => {
    console.log(link);
    QRCode.toDataURL(link, {
      width: 800,
      margin: 2,
      color: {
        dark: '#335383FF',
        light: '#EEEEEEFF'
      }
    }, (err, link) => {
      if (err) return console.error(err)

      console.log(link);
      setQr(link);
    })
  }

  const columns = [
    {
      title: "#",
      width: 60,
      align: "center",
      key: 'index',
      render: (value, item, index) => page === 1 ? (page - 1) * 10 + (++index) : (page - 1) * 10 + (++index),
      // render: (value, item, index) =>  page === 1 ? (index + 1) : ((page - 1) * item.size) + (index + 1),
    },
    {
      title: "Table No.",
      dataIndex: "tableNo",
      align: "center",
      width: 250,
      sorter: (a, b) => a.tableNo - b.tableNo,
    },
    {
      title: "Number of Pax",
      dataIndex: "Pax",
      align: "center",
      width: 200,
    },
    {
      title: "QR Code",
      dataIndex: "qr",
      width: 250,
      align: "center",
      render: (_, item) => (
        <Space size="middle">
          <QrcodeOutlined onClick={() => {
            GenerateQRCode(item.link); setQrModalOpen(true);
            setTableLinkQr(item.link); setTableNo(item.tableNo); console.log(link);
            console.log(item.link);
          }}></QrcodeOutlined>
        </Space>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: 70,
      render: (_, item) => (
        <Space size="middle">
          <EditOutlined onClick={() => {
            setPopupModal(item); setButtonText('Edit'); setTableNo(item.tableNo); setEditTable(item);
            console.log(tableNo);
          }}></EditOutlined>
          <DeleteOutlined onClick={() => { setShowConfirm(true); setEditTable(item) }}></DeleteOutlined>
        </Space>
      ),
    },
  ]

  const reset = () => {
    setSearchText("");
    getTable();
  }

  const handleInputChange = (e) => {
    setSearchText(e.target.value);
    if (e.target.value === "") {
      getTable();
    }
  };

  const globalSearch = () => {
    filteredData = modifiedData.filter((value) => {
      return (
        value.table_no.toLowerCase().includes(searchText.toLowerCase())
        // value.table_link.toLowerCase().includes(searchText.toLowerCase()) ||
        // value.table_qr.toLowerCase().includes(searchText.toLowerCase()) 
      );
    });
    setGridData(filteredData);
    setPage(1);
  }

  useEffect(() => {
    getTable();
  }, []);

  const checkData = async (value) => {
    const { data } = await axios.get(apiEndPoint);
    const item = data.filter(item => item.table_no === value.table_no);
    if (item[0]?.table_no === value.table_no) {
      submit(true, "");
    }
    else {
      submit(false, item.table_no);
    }
  }

  const addTable = async (value) => {
    if (buttonText == 'Add') {

      axios.post(apiEndPoint, {
        table_no: tableNo,
        table_link: link,
        pax: Pax,
      })
        .then((res) => {
          message.success("Table Added Succesfully");
          setNewTable();
          setValue();
          getTable();
          setPopupModal(false);
        })
        .catch((err) => {
          message.error("Something Went Wrong");
        });

    }
    else {
      axios.put(apiEndPoint + '/' + editTable._id, {
        ...value,
        table_no: tableNo,
        table_link: link,
        pax: Pax,
      })
        .then((res) => {
          message.success("Table Updated Succesfully");
          getTable();
          // setNewMember();
          setPopupModal(false);
          // setEditMember(null);
        })
        .catch((err) => {
          message.error("Something Went Wrong");
          console.log(err);
        })
    }
  };

  const handleNo = () => {
    setShowConfirm(false);
  };

  const handleDelete = async (table) => {
    try {
      console.log(table);
      await axios.delete(apiEndPoint + '/' + table._id,
        { _id: table._id });
      message.success('Table Deleted Succesfully');
      setShowConfirm(false);
      getTable();
    }
    catch (error) {
      message.error('Something Went Wrong');
      console.log(error);
    }
  };

  const downloadAll = (data) => {
    let i = 0;
    let table_no = [];
    let tableLinkArray = [];
    let tableNoArray = [];

    for (i; i < modifiedData.length; i++) {
      const table_no = modifiedData[i].table_no;
      const table_link = modifiedData[i].table_link;

      var list = QRCode.toDataURL(table_link, {
        width: 800,
        margin: 2,
        color: {
          dark: '#335383FF',
          light: '#EEEEEEFF'
        }
      }, (error, table_link) => {
        if (error) {
          return error;
        }
        tableLinkArray.push(table_link);
        tableNoArray.push(table_no);
      })
    }
  }

  const submit = (status, value) => {
    console.log("submit", status, value);
    if (status) {
      console.log("if on finish", value);
      message.error('This table number already exist')
    }
    else {
      console.log("else on finish", value);
      addTable(value);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    setTableNoData(null);
  };

  return (
    <DefaultLayout>
      <div className="d-flex justify-content-between">
        <h1>Table</h1>
        <div>
          <Button type="primary" onClick={() => { setPopupModal(true); setButtonText('Add'); setEditTable(null) }}>
            Add Table
          </Button>
        </div>
      </div>

      {
        popupModal && (
          <Modal
            // title={`${editTable !== null ? "Edit Table" : "Add Table"}`}
            title={
              <div>
                  {editTable !== null ? 'Edit Table' : 'Add Table'}
                  <CloseButton onClick={() => { setPopupModal(false); setEditTable(null); }} />
              </div>
            }
            visible={popupModal}
            onCancel={() => {
              setPopupModal(false);
              setEditTable(null);
            }}
            footer={false}>
            <Form
              name="basic"
              labelCol={{
                span: 4,
              }}
              wrapperCol={{
                span: 16,
              }}
              labelAlign="left"
              initialValues={editTable}
              onFinish={checkData}
              onFinishFailed={onFinishFailed}>
              <Form.Item
                label="Table No"
                name="table_no"
                rules={[{ required: true, message: 'Table Number is required' }]}
              >
                <Input type="text" style={{ width: 70 }}
                  value={tableNo}
                  onChange={(e) => {
                    setTableNo(e.target.value);
                  }} />
              </Form.Item>
              <Form.Item
                label="Pax"
                name="pax"
                rules={[{ required: true, message: 'Number of Pax is required' }, { pattern: new RegExp(/^[0-9]+$/), message: 'Please enter numbers only' }]}
              >
                <Input
                  type="number " style={{ width: 70 }}
                  value={Pax}
                  onChange={(e) => {
                    setPax(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <div className="d-flex justify-content-end">
                  <Button type="primary" htmlType="submit">
                    {buttonText}
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </Modal>
        )
      }
      <div>
        <Space style={{ marginBottom: 16 }}>
          <Input placeholder="Enter search text"
            onChange={handleInputChange}
            type="text"
            allowClear
            value={searchText}
          />
          <Button onClick={globalSearch} type="primary">Search</Button>
          <Button onClick={reset} type="primary">Reset</Button>
          <a href="../allQrCode" target="_blank"><Button onClick={() => { downloadAll() }} type="primary">
            Download All QR</Button></a>
        </Space>

        <Table
          columns={columns}
          dataSource={filteredData && filteredData.length ? filteredData : modifiedData}
          bordered
          loading={loading}
          pagination={{
            position: [top, bottom],
            total: modifiedData.length,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            //showQuickJumper:true,
            showSizeChanger: false,
            onChange(current) {
              setPage(current);
            }
          }}
        >
        </Table>

        {tables.map((table) => (
          showConfirm && (
            <div >
              <Dialog
                open={showConfirm}
                onClose={handleNo}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                style={{ opacity: 0.3 }}
              >
                <DialogTitle id="alert-dialog-title">
                  {"Confirm Delete"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure to delete
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button type="primary" danger
                    onClick={() => { handleDelete(editTable) }}
                    className="btn btn-info btn-sm"
                  >
                    Yes
                  </Button>
                  <Button type="primary" style={{
                    background: "#C2C2CA",
                    borderColor: "#C2C2CA"
                  }}
                    onClick={handleNo}
                    className="btn btn-danger btn-sm"
                  >
                    No
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          )
        ))}

        <Modal
          // title={`Table ${tableNo}`}
          title={
            <div>
                Table ${tableNo}
                <CloseButton onClick={() => setQrModalOpen(false)} />
            </div>
          }
          style={{ top: 20 }}
          visible={qrModalOpen}
          onCancel={() => {
            setQrModalOpen(false);
          }}
          footer={false}
        >

          <div className="inner">
            <div className="qr-row">
              <div className="qrimg-src">
                <img width={150} src={qr} />
              </div>
              <div className="qrtxt">
                <a href={qr} download={`table${tableNo}_qrcode.png`}><Button type="primary"
                  icon={<DownloadOutlined />}
                  size="large">
                  Download
                </Button></a>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </DefaultLayout>
  );
}

export default SetTable