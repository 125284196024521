import React, { createContext, useContext, useState, useCallback } from 'react';
import axios from 'axios';
import { message } from 'antd';

export const TableContext = createContext(); 

export const TableProvider = ({ children }) => {
  const [orderData, setOrderData] = useState([]);
  const [orders, setOrders] = useState([]);
  const [tableOrders, setTableOrders] = useState({ orders: [] });
  const [taxes, setTaxes] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAllOrders = async () => {
    try {
      const { data } = await axios.get("/api/orders/get-order");
      setOrderData(data);
      console.log('Orders data retrieved');
    } catch (error) {
      console.error("Error fetching orders:", error);
      message.error("Failed to fetch orders");
    }
  };

  const fetchTableOrders = async (tableNumber) => {
    try {
      let fetchedTableOrders = [];
      const { data: ordersData } = await axios.get(`/api/orders/get-order?table=${tableNumber}`);
      ordersData.forEach(order => {
        const { order_tablenumber } = order;
        if (!fetchedTableOrders[order_tablenumber]) {
          fetchedTableOrders[order_tablenumber] = [];
        }
        fetchedTableOrders[order_tablenumber].push(order);
      });
      setTableOrders(fetchedTableOrders);
    } catch (error) {
      console.error("Error fetching table orders:", error);
      message.error("Failed to fetch table orders");
    }
  };

  const fetchTaxes = async () => {
    try {
      const response = await axios.get('/api/taxes/get-taxes');
      setTaxes(response.data);
    } catch (error) {
      console.error("Error fetching taxes:", error);
      message.error("Failed to fetch taxes");
    }
  };

  const handleEditOrderStatus = async (status) => {
    try {
      const uniqueOids = [...new Set(orders.map(order => order.oid))];
      const ordersToUpdate = uniqueOids.map(oid => ({
        orderId: oid,
        order_progressstatus: status,
      }));

      const response = await axios.put('/api/orders/edit-order', {
        orders: ordersToUpdate
      });

      if (response.status === 200) {
        message.success('Orders updated successfully');
        getAllOrders();
      }
    } catch (error) {
      console.error('Error updating orders:', error);
      message.error('Failed to update orders');
    }
  };

  const handleDeleteOrder = async (record) => {
    try {
      await axios.post("/api/orders/delete-order", { orderArrayId: record._id });
      message.success('Order Deleted Successfully');
      getAllOrders();
    } catch (error) {
      message.error('Failed to delete order');
      console.error(error);
    }
  };

  const calculateTotalWithTaxes = useCallback((order) => {
    const totalWithoutTax = order.result.reduce(
      (acc, curr) => acc + curr.orderArray_price * curr.orderArray_quantity,
      0
    );
    const totalTax = taxes.reduce((acc, tax) => acc + totalWithoutTax * (tax.rate / 100), 0);
    return (totalWithoutTax + totalTax).toFixed(2);
  }, [taxes]);

  const value = {
    orderData,
    orders,
    setOrders,
    tableOrders,
    taxes,
    loading,
    setLoading,
    getAllOrders,
    fetchTableOrders,
    fetchTaxes,
    handleEditOrderStatus,
    handleDeleteOrder,
    calculateTotalWithTaxes
  };

  return <TableContext.Provider value={value}>{children}</TableContext.Provider>;
};