import React, { useEffect, useState } from 'react';
// import React from "react";
import './BillList.css'; // Linked CSS file for styling
import logo from '../assets/images/logo.png'; // Adjust the path based on the file's location

import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { getPaymentData } from '../utils/paymentData';

const PreviewReceipt = ({ orders, taxes, tableNumber, selectedOrders = [] ,  isHidden = false}) => {
    const navigate = useNavigate();
    const [currentUser, setCurrentUser] = useState(null);
    const { cashAmount, finalTotal, discountValue } = getPaymentData();

    console.log("Orders:", orders);
    console.log("Selected Orders:", selectedOrders);

    // Check for current logged-in user
    useEffect(() => {
        const auth = JSON.parse(localStorage.getItem('auth'));
        if (auth) {
          const { token } = auth;
          try {
            const decoded = jwtDecode(token);
            setCurrentUser(decoded.userId); // Set the current user
            
            // Navigate only if the user is on an invalid page
            if (window.location.pathname === '/login') {
              navigate('/'); // Redirect to the default page only if on login
            }
          } catch (err) {
            console.error('Invalid token', err);
            localStorage.removeItem('auth');
            navigate('/login'); // Redirect to login if token is invalid
          }
        } else {
          navigate('/login'); // Redirect to login if no auth is found
        }
      }, [navigate]);

    // Handle cases where orders might be undefined/null
    if (!orders || !selectedOrders.length) {
        return <div>No orders selected for receipt.</div>;
    }

    // Safely flatten the order items, fallback to an empty array if result is null/undefined
    const orderItems = orders
        .filter((order) => selectedOrders.includes(order.oid))
        .flatMap((order) => Array.isArray(order.result) ? order.result : []
    );

    const calculateTotalWithoutTaxes = () => {
        return orderItems.reduce(
            (acc, curr) => acc + curr.orderArray_price * curr.orderArray_quantity,
            0
        ).toFixed(2);
    };

    const calculateTotalWithTaxes = () => {
        const totalWithoutTax = orderItems.reduce(
            (acc, curr) => acc + curr.orderArray_price * curr.orderArray_quantity,
            0
        );
        const totalTax = taxes.reduce((acc, tax) => acc + totalWithoutTax * (tax.rate / 100), 0);
        return (totalWithoutTax + totalTax).toFixed(2);
    };

    const subTotal = calculateTotalWithoutTaxes();
    const totalWithTaxes = calculateTotalWithTaxes();
    const totalTax = (totalWithTaxes - subTotal).toFixed(2);
    const roundAdj = parseFloat(Math.round(totalWithTaxes * 20) / 20 - totalWithTaxes).toFixed(2);

    return (
        <div>
            <style>{`
                // .receipt-container {
                //     font-family: Arial, sans-serif;
                //     max-width: 300px;
                //     margin: 20px auto;
                //     padding: 20px;
                //     border: 1px solid #ddd;
                //     border-radius: 8px;
                //     background-color: #f9f9f9;
                // }

                .receipt-header {
                    text-align: center;
                    margin-bottom: 20px;
                }

                .receipt-details {
                    margin-bottom: 20px;
                    line-height: 1.5;
                }

                .receipt-table {
                    width: 100%;
                    border-collapse: collapse;
                    margin-bottom: 20px;
                }

                .receipt-table th,
                .receipt-table td {
                    padding: 8px;
                    border-bottom: 1px solid #ddd;
                }

                .receipt {
                    margin-top: 20px;
                }

                .receipt-row {
                    display: flex;
                    justify-content: space-between;
                    font-size: 10px;
                    font-family: Arial;
                    margin: 5px 0;
                }

                .label {
                    flex: 3;
                    text-align: right;
                }

                .value {
                    flex: 1;
                    text-align: right;
                }

                .separator {
                    text-align: right;
                    margin: 10px 0;
                    color: #999;
                }

                .receipt-row receipt-total{
                    display: flex;
                    justify-content: space-between;
                    font-family: Arial;
                    margin: 5px 0;

                }



                .receipt-footer {
                    text-align: center;
                    margin-top: 20px;
                    font-style: Arial;
                    color: #555;
                    font-size: 15px;
                }
            `}
            </style>
        <div className="receipt-container">
            <div className="receipt-header">
                {/* <img 
                    src="client\src\assets\images\logo.png" 
                    alt="Restaurant Logo" 
                    style={{ width: '100px', height: 'auto', marginBottom: '10px' }} 
                /> */}
               <img 
                    src={logo} 
                    alt="Restaurant Logo" 
                    style={{ width: '100px', height: 'auto', marginBottom: '10px' }} 
                />
                <p><strong>Receipt</strong></p>
                <p><strong>Restaurant Name</strong></p>
                <p style={{ fontSize: '10px'}}>No 1-2, Jalan 1/1,<br /> 46000 Petaling Jaya,<br /> Selangor</p>
            </div>
            {/* <div className="receipt-details" style={{}}>
                <p><strong>Table Number:</strong> {orders[0]?.order_tablenumber}</p>
                <p><strong>Date:</strong> {new Date().toLocaleString()}</p>
                <p><strong>Cashier Name:</strong> {currentUser}</p>
            </div> */}

            <div className="receipt-details" style={{ textAlign: 'left' }}>
                <div style={{ 
                    display: 'grid', 
                    gridTemplateColumns: 'auto 1fr', 
                    columnGap: '2px', 
                    rowGap: '2px' 
                }}>
                    <p><strong>Table Number: </strong></p>
                    <p>{orders[0]?.order_tablenumber}</p>
                    <p><strong>Date & Time: </strong></p>
                    <p>{new Date().toLocaleString()}</p>
                    <p><strong>Cashier Name: </strong></p>
                    <p>{currentUser}</p>
                </div>
            </div>

            <table className="receipt-table">
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Qty</th>
                        <th style={{textAlign: "right"}}>Total (RM)</th>
                    </tr>
                </thead>
                <tbody>
                    {orderItems.map((item, index) => (
                        <tr key={index}>
                            <td>{item.orderArray_productName}</td>
                            <td>{item.orderArray_quantity}</td>
                            <td style={{textAlign: "right"}}>{(Number(item.orderArray_quantity) * Number(item.orderArray_price)).toFixed(2)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="receipt">
                <div className="receipt-row">
                    <span className="label"></span>    
                    <span className="label">Sub Total:</span>
                    <span className="value">RM {subTotal}</span>
                </div>
                <div className="receipt-row">
                    <span className="label"></span> 
                    <span className="label">Total Tax (16%):</span>
                    <span className="value">RM {totalTax}</span>
                </div>
                <div className="receipt-row">
                    <span className="label"></span> 
                    <span className="label">Round Adj:</span>
                    <span className="value">RM {roundAdj}</span>
                </div>
                <div className="separator">--------------------------</div>
                <div className="receipt-row receipt-total">
                    <span className="label"></span> 
                    <span className="label">Grand Total:</span>
                    <span className="value">RM {parseFloat(Math.round(totalWithTaxes * 20) / 20).toFixed(2)}</span>
                </div>
            </div>

            <div className="receipt-footer">
                <p>Note: This is not the finalize receipt.</p>
            </div>
        </div>
        </div>
    );
};

export default PreviewReceipt;


// import React from "react";
// import './BillList.css'; // If you need custom styles for the receipt

// const Receipt = ({ orders, taxes }) => {
//     if (!orders.length) return <div>No orders available.</div>;

//     const orderItems = orders.flatMap(order => order.result);

//     const calculateTotalWithTaxes = (orders) => {
//         const totalWithoutTax = orderItems.reduce(
//             (acc, curr) => acc + curr.orderArray_price * curr.orderArray_quantity,
//             0
//         );
//         const totalTax = taxes.reduce((acc, tax) => acc + totalWithoutTax * (tax.rate / 100), 0);
//         return (totalWithoutTax + totalTax).toFixed(2);
//     };

//     const calculateTotalWithoutTaxes = (orders) => {
//         return orderItems.reduce(
//             (acc, curr) => acc + curr.orderArray_price * curr.orderArray_quantity,
//             0
//         ).toFixed(2);
//     };

//     const subTotal = calculateTotalWithoutTaxes();
//     const totalWithTaxes = calculateTotalWithTaxes();
//     const totalTax = (totalWithTaxes - subTotal).toFixed(2);

//     const handlePrint = () => {
//         const printWindow = window.open('', '', 'width=600,height=400');
//         const printContent = document.getElementById('printable-receipt').innerHTML;

//         printWindow.document.write(`
//             <html>
//                 <head>
//                     <title>Print Receipt</title>
//                     <style>
//                         @media print {
//                             body {
//                                 margin: 0;
//                                 padding: 0;
//                                 font-family: Arial, sans-serif;
//                             }
//                             .receipt-container {
//                                 max-width: 58mm;
//                                 margin: 0;
//                                 padding: 0;
//                             }
//                         }
//                     </style>
//                 </head>
//                 <body>
//                     ${printContent}
//                 </body>
//             </html>
//         `);

//         printWindow.document.close();
//         printWindow.focus();
//         printWindow.print();
//         printWindow.close();
//     };

//     return (
//     	<div>
//          <div id="printable-receipt" className="receipt-container">
//             <div className="receipt-header">
//                 <p>**Receipt**</p>
//                 <p><strong>Restaurant Name</strong></p>
//                 <p>No 1-2, Jalan 1/1,<br /> 46000 Petaling Jaya,<br /> Selangor</p>
//             </div>
//             <div className="receipt-details">
//                 <p><strong>Table Number:</strong> {orders[0]?.order_tablenumber}</p>
//                 <p><strong>Date:</strong> {new Date().toLocaleString()}</p>
//             </div>
//             <table className="receipt-table">
//                 <thead>
//                     <tr>
//                         <th>Item</th>
//                         <th>Qty</th>
//                         <th>Total</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {orderItems.map((item, index) => (
//                         <tr key={index}>
//                             <td>{item.orderArray_productName}</td>
//                             <td>{item.orderArray_quantity}</td>
//                             <td>{(Number(item.orderArray_quantity) * Number(item.orderArray_price)).toFixed(2)}</td>
//                         </tr>
//                     ))}
//                 </tbody>
//             </table>
//             <div className="receipt-subtotal">
//                 Sub Total: RM{" "}
//                 <span>{calculateTotalWithoutTaxes(orders)}</span>
//             </div>
//             <div className="receipt-subtotal">
//                 Total Tax (16%): RM{" "}
//                 <span>{(calculateTotalWithTaxes(orders) - calculateTotalWithoutTaxes(orders)).toFixed(2)}</span>
//             </div>
//             <div className="receipt-total">
//                 <p>--------------------------</p>
//                 Grand Total: RM{" "}
//                 <span>{calculateTotalWithTaxes(orders)}</span>
//             </div>
//             <div className="receipt-footer">
//                 <p>Thank you! Please come again :)</p>
//             </div>
//             <div style={{ textAlign: 'right', marginTop: '20px' }}>
//                 <button onClick={handlePrint}>
//                     Print Order Receipt
//                 </button>
//             </div>
//         </div>
//         </div>
//     );
// };

// export default Receipt;

