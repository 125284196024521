import 'antd/dist/antd.min.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { TableProvider } from './contexts/TableContext';
import Homepage from './pages/Homepage';
import ItemPage from './pages/ItemPage';
import SetTable from './pages/SetTable';
import SetTax from './pages/SetTax';
import SetMember from './pages/SetMember';
import AllQrCode from './pages/AllQrCode';
import SetCategory from './pages/SetCategory';
import SetMenu from './pages/SetMenu';
import TableSetting from './pages/TableSetting';
import Bills from './pages/Bills';
import Staff from './pages/Staff';
import Report from './pages/Report';
import Login from './pages/Login';
import PrivateRoute from './routes/privateRoute';
import { ValidationProvider } from './contexts/ValidationContext';

// Wrapper component for routes that need TableContext
const TableContextWrapper = ({ children }) => (
  <TableProvider>
    {children}
  </TableProvider>
);

// Wrapper component that combines PrivateRoute and TableContext
const PrivateTableRoute = ({ children, role }) => (
  <PrivateRoute role={role}>
    <TableContextWrapper>
      {children}
    </TableContextWrapper>
  </PrivateRoute>
);

function App() {
  return (
    <ValidationProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          
          {/* Routes that need TableContext */}
          <Route path="/" element={
            <PrivateTableRoute>
              <Homepage />
            </PrivateTableRoute>
          } />
          
          <Route path="/items" element={
            <PrivateTableRoute>
              <ItemPage />
            </PrivateTableRoute>
          } />
          
          <Route path="/category" element={
            <PrivateTableRoute>
              <SetCategory />
            </PrivateTableRoute>
          } />
          
          <Route path="/setTable" element={
            <PrivateTableRoute>
              <SetTable />
            </PrivateTableRoute>
          } />
          
          <Route path="/tables" element={
            <PrivateTableRoute>
              <TableSetting />
            </PrivateTableRoute>
          } />
          
          <Route path="/setTax" element={
            <PrivateTableRoute>
              <SetTax />
            </PrivateTableRoute>
          } />
          
          <Route path="/setMember" element={
            <PrivateTableRoute>
              <SetMember />
            </PrivateTableRoute>
          } />
          
          <Route path="/allQrCode" element={
            <PrivateTableRoute>
              <AllQrCode />
            </PrivateTableRoute>
          } />
          
          <Route path="/menu" element={
            <PrivateTableRoute>
              <SetMenu />
            </PrivateTableRoute>
          } />
          
          <Route path="/bills" element={
            <PrivateTableRoute>
              <Bills />
            </PrivateTableRoute>
          } />
          
          {/* Admin routes */}
          <Route path="/staff" element={
            <PrivateTableRoute role="admin">
              <Staff />
            </PrivateTableRoute>
          } />
          
          <Route path="/report" element={
            <PrivateTableRoute role="admin">
              <Report />
            </PrivateTableRoute>
          } />
        </Routes>
      </BrowserRouter>
    </ValidationProvider>
  );
}

export default App;

//export function ProtectedRoute({children}){
  //if(localStorage.getItem('auth')){
  //  return children
  //}
  //else{
   // return <Navigate to="/login" />;
 // }
//}