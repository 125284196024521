import React, { useState, useEffect } from "react";
import { Popconfirm, Modal, Button, Table, Form, Input, Select, message,Upload } from "antd";
import { FilterDropdown } from "antd/lib/table/hooks/useFilter";
import axios from "axios";
import DefaultLayout from '../components/DefaultLayout';
import slugify from "slugify";
import CloseButton from "../components/CloseButton";
import './SetCategory.css';
import imageCompression from 'browser-image-compression';
import { UploadOutlined } from "@ant-design/icons";

import {
  DeleteOutlined,
  EditOutlined,
  CaretDownOutlined, CaretUpOutlined
} from "@ant-design/icons";

const { TextArea } = Input;
const ProductsTable = () => {

  const [itemsData, setItemsData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [popupModal, setPopupModal] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [categoryFilters, setCategoryFilters] = useState([]);
  const [newPid, setNewPid] = useState('');
  const [categoryOpen, setCategoryOpen] = useState(false); 
  const [statusOpen, setStatusOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [fileUpload, setFile] = useState(null);
  const [previousImageKey, setPreviousImageKey] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [originalImage ,setOriginalImageURLEdit] = useState(null);
  const [isUploading, setIsUploading] = useState(false);  
  const [uploadTimeout, setUploadTimeout] = useState(null);
  var [countImageUploaded, setCountImage] = useState(0);

  const handleCategoryDropdownVisibleChange = (open) => {
    setCategoryOpen(open);
  };

  const handleStatusDropdownVisibleChange = (open) => {
    setStatusOpen(open);
  };
  const handleCategoryFilter = (value, record) => {
    return record.category === value;
  };

  const handleCategoryFiltersChange = (filters) => {
    setCategoryFilters(filters);
  };
  const getAllItems = async () => {
    try {

      const { data } = await axios.get("/api/products/get-product");

      setItemsData(data);

      console.log(data);

    } catch (error) {
      console.log(error);
    }

  };

  const getAllCategories = async () => {
    try {
      const { data } = await axios.get("/api/menus/get-category");
      setCategories(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {

    getAllItems();
    getAllCategories();
  }, []);


  //handle delete
  const handleDelete = async (record) => {
    try {


      await axios.post("/api/products/delete-product", { productId: record._id });
      message.success('Food Deleted Successfully')
      getAllItems();
      setPopupModal(false);

    } catch (error) {
      message.success('Something Went Wrong')
      console.log(error);
    }
  }

  useEffect(() => {
    // This effect will run when the editCategory is set (when fetching data for editing)
    if (editItem && editItem.image) {
      // If there's an existing image, set it as the initial image
      const extractedFilename = editItem.image.match(/[^/]+$/)[0];
      setImageUrl(editItem.image);
      setPreviousImageKey(new URL(editItem.image).pathname.slice(1)); // Extract the key from the URL
      // Initialize the fileList with the fetched image (for display in the Upload component)
      setFileList([
        {
          uid: "-1",  // Unique identifier for the file
          name: extractedFilename,  // Display name
          status: "done",  // Mark it as uploaded
          url: editItem.image,  // Set the fetched image URL
        },
      ]);
    }
  }, [editItem]);

  //able data
  const columns = [

    { title: "Name", dataIndex: "name" },
    { title: "Description", dataIndex: "description" },
    {
      title: "Image",
      dataIndex: "image",
      render: (image, record) => (
        <img src={record.image}
          alt={record.name} height="60" width="60" />
      ),
    },
    {
      title: 'Category',
      dataIndex: 'category',
      filters: categories.map(category => ({
        text: category.name,
        value: category.name,
      })),
      onFilter: (value, record) => record.category === value,
    },


    { title: "Price", dataIndex: "price" },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => (status ? "Available" : "Unavailable"),
      filters: [
        {
          text: "Available",
          value: true,
        },
        {
          text: "Not Available",
          value: false,
        },
      ],
      onFilter: (value, record) => record.status === value,
    },
    {
      title: "Actions",
      dataIndex: "_id",
      render: (id, record) => (
        <div className="actions-column">
          <EditOutlined
            style={{
              cursor: "pointer"
            }}
            onClick={() => openModal(record)} />
          <Popconfirm
            title="Are you sure you want to delete this record?"
            onConfirm={() => handleDelete(record)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined
              style={{ cursor: "pointer", marginLeft: 8 }}
            />
          </Popconfirm>

        </div>
      ),

    },
  ];
  //handle name change
  const handleNameChange = (event) => {
    const { value } = event.target;
    Form.setFieldsValue({ slug: slugify(value) });
  };

  const deleteImageFromS3 = async (key) => {
    // console.log("Deleting key:", key); // Debug
    if (!key) {
      console.error("No key provided for deletion");
      return; // Prevent calling the API with a null/undefined key
    }
    try {
      // console.log("Start Perform API");
      // await axios.post("http://localhost:8080/api/delete", {
      //   data: { key },
      // });
      await axios.post("/api/image/delete", { key }, {
        headers: {
          'Content-Type': 'application/json', // Ensure JSON payload
        },
      });
      // console.log("Done.");
      // message.success("Image deleted successfully");
    } catch (error) {
      // console.error("Failed to delete image:", error);
      // console.error("Failed to delete image. Error:", error.response?.data || error.message);
      // message.error("Failed to delete image");
      // 
    }
  };

  const openModal = (item = null) => {
    if (item) {
      setEditItem(item); // Set the category to edit
      setImageUrl(item.image); // Set the existing image URL
      // console.log(" Image",imageUrl);
      setPreviousImageKey(new URL(item.image).pathname.slice(1)); // Extract S3 key from URL
      setOriginalImageURLEdit(item.image);
      // console.log("Original Image",originalImage);
      // Populate the fileList with the existing image
      setFileList([
        {
          uid: "-1", // Unique identifier for the file
          name: "Existing Image", // Display name
          status: "done", // Mark it as uploaded
          url: item.image, // Existing image URL
        },
      ]);
    } else {
      setEditItem(null); // For adding a new category
      setImageUrl(null); // Clear image URL
      setPreviousImageKey(null); // Clear the previous image key
      setOriginalImageURLEdit(null);
      setFileList([]); // Clear the file list
    }
  
    setPopupModal(true); // Open the modal
  };

   //handle previous image
  //  const handleImageUpload = async (file) => {
  //   // Log the file size in bytes and MB
  //   // console.log("File size in bytes:", file.size); // Log in bytes
  //   // console.log("File size in MB:", (file.size / 1024 / 1024).toFixed(2)); // Log in MB

  //     if (file.size > 20 * 1024 * 1024) { // 10MB
  //       message.error("File size exceeds 10MB limit. Please choose a smaller file.");
  //       return;
  //     }

      
  //     // Initialize the file with status 'uploading' and 0% progress
  //     setFileList((prevList) => [
  //       ...prevList,
  //       {
  //         uid: file.uid,
  //         name: file.name,
  //         status: "uploading",
  //         percent: 0,
  //       },
  //     ]);

  //     setIsUploading(true); // Set uploading to true


  //     // Compress the image
  //     const options = {
  //       maxSizeMB: 1, // Adjust compression size (1MB target)
  //       maxWidthOrHeight: 1920, // Optionally resize to max dimensions
  //       useWebWorker: true,
  //     };

  //     let compressedFile;
  //     try {
  //       compressedFile = await imageCompression(file, options);
  //       // console.log("Original file size:", file.size / 1024 / 1024, "MB");
  //       // console.log("Compressed file size:", compressedFile.size / 1024 / 1024, "MB");
  //     } catch (err) {
  //       message.error("Image compression failed.");
  //       // console.error(err);
  //       setIsUploading(false);
  //       return;
  //     }

  //     const formData = new FormData();
  //     formData.append("image", compressedFile);

  //     // Set a timeout to handle long uploads
  //     const timeout = setTimeout(() => {
  //       message.error("Upload is taking a few time. Please try again.");
  //       setIsUploading(false);
  //       setFileList((prevList) =>
  //         prevList.map((item) =>
  //           item.uid === file.uid ? { ...item, status: "error" } : item
  //         )
  //       );
  //     }, 30000); 

  //     setUploadTimeout(timeout); // Store the timeout ID

  //     try {
        
  //       const config = {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //         onUploadProgress: (progressEvent) => {
  //           const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);

  //           // Update the file list with progress percentage
  //           setFileList((prevList) =>
  //             prevList.map((item) =>
  //               item.uid === file.uid ? { ...item, percent } : item
  //             )
  //           );
  //         },
  //       };

  //       const response = await axios.post("http://localhost:8080/api/image/upload", formData, config);

  //       const uploadedUrl = response.data.url;
  //       const uploadedKey = new URL(uploadedUrl).pathname.slice(1); // Extract S3 key
  //       // console.log("Extracted Key:", uploadedKey);

  //       countImageUploaded = countImageUploaded + 1;
  //       setCountImage(countImageUploaded);
  //       // console.log("Counting: ",countImageUploaded);

  //       // Delete the previous image if it exists
  //       if (editItem && countImageUploaded >= 2 && previousImageKey){
  //         // console.log("Try to delete edit image");
  //         await deleteImageFromS3(previousImageKey);
  //       }else if (previousImageKey &&!editItem) {
  //         // console.log("Deleting current image key:", previousImageKey);
  //         await deleteImageFromS3(previousImageKey);
  //       }

  //       setImageUrl(uploadedUrl);
  //       setPreviousImageKey(uploadedKey);
  //       clearTimeout(timeout);

  //       setFileList((prevList) =>
  //         prevList.map((item) =>
  //           item.uid === file.uid ? { ...item, status: "done", url: uploadedUrl } : item
  //         )
  //       );

  //       message.success("Image uploaded successfully!");
  //     } catch (error) {
  //       message.error("Image upload failed!");
  //       setFileList((prevList) =>
  //         prevList.map((item) =>
  //           item.uid === file.uid ? { ...item, status: "error" } : item
  //         )
  //       );
  //     }finally {
  //       setIsUploading(false); // Reset uploading status
  //     }
  //   };

  //handle submit
  const handleSubmit = async (value) => {
    if (isUploading) {
      message.warning("Please wait until the upload is complete before submit your form.");
      return false; // Prevent the removal
    }
    const { name } = value;

    let slug;
    if (editItem === null) {
      const res = await axios.get("/api/products/latest-pid");
      const latestPid = res.data.pid;
      const newPid = "P" + String(parseInt(latestPid.slice(1)) + 1).padStart(7, "0");


      try {

        const dateTime = new Date();
        const createdAt = dateTime.toLocaleString();

        slug = slugify(name);

        const newValues = { ...value, slug, pid: newPid, createdAt, image: imageUrl};

        try {
          const uploadedImageUrl = await uploadImageToS3(fileUpload);
          newValues.image = uploadedImageUrl;
          console.log("See new value:", newValues.image);

        } catch (uploadError) {
          // If image upload fails, clear the file list and stop submission
          message.error("Image upload failed. Please try again.");
          setFile(null);
          setFileList([]); // Clear the file list
          return; // Stop the submission process
        }

        const res = await axios.post("/api/products/add-product", newValues);
        message.success('Food Added Successfully')
        getAllItems();
        setCountImage(0);
        setOriginalImageURLEdit(null);
        setImageUrl(null);
        setPopupModal(false);
        setFileList([]);
      } catch (error) {
        message.success('Something Went Wrong')
        console.log(error);
      }
    } else {
      try {
        // console.log(imageUrl);
        // console.log(originalImage);
        // let finalImageUrl = "";
        // if (imageUrl !== originalImage && setFileList!=null) {
        //   // Delete the old image from S3 if the image URL has changed
        //   // console.log("Try here before...");
        //   const previousImageKey = originalImage.split('/').pop();
        //   await deleteImageFromS3(previousImageKey);
        //   finalImageUrl = imageUrl;
        // }
        // if ((imageUrl == null && originalImage) || (imageUrl && originalImage != null)) {
        //   setCountImage(0);
        //   // updatedValues.image = originalImage; // Update `updatedValues` directly
        //   finalImageUrl = originalImage;
        //   setImageUrl(finalImageUrl);    
        // }

        let finalImageUrl = "";
        if (imageUrl !== originalImage && imageUrl != null) {
          
          try {
            finalImageUrl = await uploadImageToS3(fileUpload);
          } catch (uploadError) {
            // If image upload fails, clear the file list and stop submission
            message.error("Image upload failed. Please try again.");
            setFile(null);
            setFileList([]); // Clear the file list
            return; // Stop the submission process
          }
        }else{
          finalImageUrl = originalImage;
        }

        slug = slugify(name);
        await axios.put("/api/products/edit-product", { ...value, slug, productId: editItem._id , image: finalImageUrl});
        message.success('Food Updated Successfully')
        getAllItems();
        setCountImage(0);
        setOriginalImageURLEdit(null);
        setEditItem(null); 
        setPopupModal(false);
        setFileList([]);
      } catch (error) {
        message.error('Something Went Wrong')
        console.log(error);
      }
    }
  };

  const handleCancel = async () => {
    // Prevent canceling if upload is still in progress
    if (isUploading) {
      message.error("Please wait until the image upload is complete before canceling.");
      return;
    }

    // try {
     
    // } catch (error) {
    //   message.error("Something went wrong.");
    // } finally {
      // Reset states after canceling
      // setCountImage(0);
      setOriginalImageURLEdit(null);
      setFile(null);
      setImageUrl(null);
      setFileList([]);
      setEditItem(null); // Clear the edit state
      setPopupModal(false); // Close the modal
    // }
  };

  const handleImageUpload = async (file) => {
    const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    if (!allowedTypes.includes(file.type)) {
      message.error("Invalid file type. Only JPG, JPEG and PNG are allowed.");
      return;
    }
    
    // Check if file size exceeds 20MB
    if (file.size > 20 * 1024 * 1024) {
      message.error("File size exceeds 20MB limit. Please choose a smaller file.");
      return;
    }
  
    // Initialize the file with status 'uploading' and 0% progress for preview purposes
    setFileList((prevList) => [
      ...prevList,
      {
        uid: file.uid,
        name: file.name,
        status: "uploading", // Image is uploading
        percent: 0,          // Set progress to 0 initially
      },
    ]);
  
    setIsUploading(true); // Set uploading to true
  
    // Compress the image before upload
    const options = {
      maxSizeMB: 1, // Adjust compression size (1MB target)
      maxWidthOrHeight: 1920, // Optionally resize to max dimensions
      useWebWorker: true,
    };
  
    let compressedFile;
    let timeout;
  
    try {
      compressedFile = await imageCompression(file, options);
    } catch (err) {
      message.error("Image compression failed.");
      setIsUploading(false);
      return;
    }
  
    try {
      // Create a local object URL for preview purposes
      const localUrl = URL.createObjectURL(compressedFile);
      setImageUrl(localUrl); // Set the image URL for preview
      setFile(compressedFile);
  
      setFileList((prevList) =>
        prevList.map((item) =>
          item.uid === file.uid ? { ...item, status: "done", url: localUrl } : item
        )
      );
      message.success("Image ready for upload!");
  
      // Start a timeout for the upload process
      timeout = setTimeout(() => {
        message.error("Upload is taking too long. Please try again.");
        setIsUploading(false);
        setFileList((prevList) =>
          prevList.map((item) =>
            item.uid === file.uid ? { ...item, status: "error" } : item
          )
        );
      }, 30000); // 30 seconds timeout
      setUploadTimeout(timeout); // Store the timeout ID
    } catch (error) {
      message.error("Image upload failed! Please try again.");
      setFileList((prevList) =>
        prevList.map((item) =>
          item.uid === file.uid ? { ...item, status: "error" } : item
        )
      );
    } finally {
      // Ensure timeout is cleared after handling the upload result
      if (timeout) {
        clearTimeout(timeout);
      }
      setIsUploading(false); // Reset uploading status
    }
  };
  
  const uploadImageToS3 = async (file) => {
    try {
      const formData = new FormData();
      formData.append("image", file); // Append the file object directly
  
      const response = await axios.post("/api/image/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
  
      if (response.data && response.data.url) {
        message.success("Image uploaded successfully!");
        return response.data.url; // Return the URL of the uploaded image
      } else {
        // If the response is invalid or does not contain the URL
        throw new Error("Failed to upload image. Please try again.");
      }
    } catch (error) {
      // message.error(error.response?.data?.message || "Image upload failed. Please try again.");
      console.error("Image upload error:", error);
      throw error; // Re-throw the error to be caught in the outer try-catch
    }
  };

  return (
    <DefaultLayout>
      <div className="d-flex justify-content-between">
        <h1>Menu List</h1>
        <Button type="primary" onClick={() => setPopupModal(true)}>Add Menu</Button>
      </div>

      <Table columns={columns} dataSource={itemsData} bordered />


      {
        popupModal && (
          <Modal 
            // title={`${editItem !== null ? 'Edit Menu' : 'Add New Menu'}`}
            title={
              <div>
                  {editItem !== null ? 'Edit Menu' : 'Add New Menu'}
                  <CloseButton onClick={() => { setEditItem(null); setPopupModal(false); }} />
              </div>
            }
            open={popupModal}
            onCancel={handleCancel}
            footer={false}>
            <Form layout="vertical" initialValues={editItem} onFinish={handleSubmit}>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  { required: true, message: 'Please enter the name' },
                  { min: 3, message: 'Name must be at least 3 characters' },
                ]}
              >
                <Input onChange={handleNameChange} />
              </Form.Item>
              <Form.Item name="slug" label="slug" hidden>
                <Input />
              </Form.Item>
              <Form.Item
                name="description"
                label="Description"
                rules={[
                  { required: true, message: 'Please enter a description' },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="price"
                label="Price"
                rules={[
                  { required: true, message: 'Please enter the price' },
                  { pattern: /^[0-9]+(\.[0-9]{1,2})?$/, message: 'Please enter a valid price' },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="quantity"
                label="Max Quantity"
                rules={[
                  { required: true, message: 'Please enter the quantity' },
                  { pattern: /^[0-9]+$/, message: 'Quantity must be a number' },
                ]}
              >
                <Input />
              </Form.Item>
              
              <Form.Item
                name="image"
                label="Image Upload"
                rules={[{ required: true, message: "Please upload an image!" }]}
              >
                {/* handle previous image */}
                <Upload
                  className="upload-container"
                  listType="picture"
                  fileList={fileList}
                  customRequest={({ file }) => handleImageUpload(file)}
                  onRemove={async (file) => {
                    if (isUploading) {
                      message.warning("Please wait until the upload is complete before removing the file.");
                      return false; // Prevent the removal
                    }

                    if(file.url && editItem){
                      setFileList([]);
                    }
                    // if (file.url && previousImageKey &&!editItem) {
                    //   try {
                    //     // console.log("Deleting previous image key:", previousImageKey);
                    //     await deleteImageFromS3(previousImageKey);
                    //     setPreviousImageKey(null);
                    //     // message.success("Image removed successfully!");
                    //   } catch (error) {
                    //     // console.error("Failed to delete image:", error);
                    //     // message.error("Failed to remove image.");
                    //   }
                    // }
                    setFile(null);
                    setFileList([]);
                    setImageUrl(null);
                  }}
                >
                  {fileList.length < 1 && <Button  icon={<UploadOutlined />} className="upload-button">Upload Image</Button>}
                </Upload>
              </Form.Item>




              {/* <Form.Item
                name="image"
                label="Image URL"
                rules={[
                  { required: true, message: 'Please enter an image URL' },
                  { type: 'url', message: 'Please enter a valid URL' },
                ]}
              >
                <Input />
              </Form.Item>


              <Form.Item name="pid" label="pid" hidden>
                <Input value={newPid} />
              </Form.Item> */}

              {/* <Form.Item
                name="category"
                label="Category"
                rules={[
                  { required: true, message: 'Please select a category' },
                ]}
              >
                <Select>
                  {categories.map((category) => (
                    <Select.Option key={category._id} value={category.name}>
                      {category.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item> */}
              <Form.Item
                name="category"
                label="Category"
                rules={[{ required: true, message: 'Please select a category' }]}
              >
                <Select
                  onDropdownVisibleChange={handleCategoryDropdownVisibleChange } 
                  suffixIcon={categoryOpen  ? <CaretUpOutlined /> : <CaretDownOutlined />} 
                >
                  {categories.map((category) => (
                    <Select.Option key={category._id} value={category.name}>
                      {category.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              {/* <Form.Item
                name="status"
                label="Availability"
                rules={[
                  { required: true, message: 'Please select availability status' },
                ]}
              >
                <Select>
                  <Select.Option value={true}>Available</Select.Option>
                  <Select.Option value={false}>Not Available</Select.Option>
                </Select>
              </Form.Item> */}
              <Form.Item
                name="status"
                label="Availability"
                rules={[{ required: true, message: 'Please select availability status' }]}
              >
                <Select
                  onDropdownVisibleChange={handleStatusDropdownVisibleChange }
                  suffixIcon={statusOpen  ? <CaretUpOutlined /> : <CaretDownOutlined />}
                >
                  <Select.Option value={true}>Available</Select.Option>
                  <Select.Option value={false}>Not Available</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item name="createdAt" label="Created At" hidden>
                <Input value={new Date()} />
              </Form.Item>

              <div className="d-flex justify-content-end ant-modal-footer">
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </div>

            </Form>
          </Modal>
        )
      }

    </DefaultLayout>
  );


};

export default ProductsTable;
