import React, { useEffect, useState } from 'react';
import './BillList.css'; // Custom styles for the receipt
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const OrderReceipt = ({ orders, tableNumber, selectedOrders = [] }) => {
    const navigate = useNavigate();
    const [currentUser , setCurrentUser ] = useState(null);
    console.log("Orders:", orders);
    console.log("Selected Orders:", selectedOrders);

    const orderItems = orders
        .filter((order) => selectedOrders.includes(order.oid))
        .flatMap((order) => Array.isArray(order.result) ? order.result : []);

    useEffect(() => {
        const auth = JSON.parse(localStorage.getItem('auth'));
        if (auth) {
            const { token } = auth;
            try {
                const decoded = jwtDecode(token);
                setCurrentUser (decoded.userId);
                if (window.location.pathname === '/login') {
                    navigate('/');
                }
            } catch (err) {
                console.error('Invalid token', err);
                localStorage.removeItem('auth');
                navigate('/login');
            }
        } else {
            navigate('/login');
        }
    }, [navigate]);

    if (!orderItems.length) {
        return <div>No orders pending for kitchen.</div>;
    }

    const totalQuantity = orderItems.reduce((sum, item) => sum + item.orderArray_quantity, 0);

    const handlePrint = () => {
        const printWindow = window.open('', '', 'width=600,height=400');
        const printContent = document.getElementById('printable-order-receipt').innerHTML;

        printWindow.document.write(`
            <html>
                <head>
                    <title>Print Order Receipt</title>
                    <style>
                        @media print {
                            body {
                                margin: 0;
                                padding: 0;
                                font-family: Arial, sans-serif;
                            }
                            .receipt-container {
                                max-width: 58mm;
                                margin: 0;
                                padding: 0;
                            }
                            table {
                                width: 100%;
                                border-collapse: collapse;
                            }
                            th, td {
                                border: none;
                                padding: 5px;
                                text-align: left;
                            }
                        }
                    </style>
                </head>
                <body>
                    ${printContent}
                </body>
            </html>
        `);

        printWindow.document.close();
        printWindow.focus();
        printWindow.print();

        setTimeout(() => {
            printWindow.close();
        }, 500);
    };

    return (
        <div>
            <div id="printable-order-receipt" className="receipt-container">
                <div className="receipt-header">
                    <p>**Order Receipt**</p>
                    <p><strong>Table Number:</strong> {tableNumber}</p>
                    <p><strong>Date:</strong> {new Date().toLocaleString()}</p>
                    <p><strong>Cashier Name: </strong>{currentUser }</p>
                    <p></p>
                </div>
                <table className="receipt-table">
                    <thead>
                        <tr>
                            <th>Qty</th>
                            <th>Item</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {orderItems.length > 0 ? (
                            orderItems.map((item, index) => (
                                <React.Fragment key={index}>
                                    <tr>
                                        <td style={{ width: '50px' }}>{item.orderArray_quantity}</td>
                                        <td>{item.orderArray_productName}</td>
                                        <td style={{ textAlign:'center' }}>[&nbsp;&nbsp;&nbsp;&nbsp;]</td> 
                                    </tr>
                                    {item.orderArray_remark && (
                                        <tr>
                                            <td></td>
                                            <td style={{ paddingLeft: '20px', fontStyle: 'italic' }}>
                                                - {item.orderArray_remark}
                                            </td>
                                            <td></td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="3">No items in the selected orders.</td>
                            </tr>
                        )}
                    </tbody>
                    <tfoot>
                        <tr style={{ borderTop: "1px solid #ddd" }}>
                            <td><strong>Total</strong></td>
                            <td></td>
                            <td style={{textAlign:'center'}}><strong >{totalQuantity}</strong></td>
                            
                        </tr>
                    </tfoot>
                </table>
            </div>
            
            <div style={{ textAlign: 'right', marginTop: '20px' }}>
                <button onClick={handlePrint}>
                    Print Order Receipt
                </button>
            </div>
        </div>
    );
};

export default OrderReceipt;