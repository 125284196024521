import React, {useEffect} from 'react';
import { Form, Input, Button, message } from 'antd';
import { useNavigate} from 'react-router-dom';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

//import {useDispatch} from 'react-redux';

const Login = () => {

  const navigate = useNavigate();

  const handleSubmit = async (value) => {
    try {
      const { userId, password } = value;
      const res = await axios.post("/api/users/login", value);
      message.success('Login successful');

      const token = res.data.token;
      const decoded = jwtDecode(token);
      
      localStorage.setItem('auth', JSON.stringify({token, role:decoded.role}));
      navigate("/");
 
    } catch (error) {
      message.error('Login failed');
      console.log(error);
    }
  };

  //current log in user
  useEffect(() => {
    if (localStorage.getItem('auth')) {
      navigate('/');
    }
  }, [navigate]);

  return (
    <>
    <div className="login-container">
      <div className="login-box">
        <div className="login-header">
          <h1>Welcome to POS App</h1>
          <p>Login to continue</p>
        </div>
  
        <Form layout="vertical" onFinish={handleSubmit}>
          <Form.Item name="userId" label="User ID">
            <Input className="input-field" placeholder="Enter your User ID" />
          </Form.Item>
  
          <Form.Item name="password" label="Password">
            <Input.Password className="input-field" placeholder="Enter your password" />
          </Form.Item>
  
          <div className="footer">
            <Button type="primary" htmlType="submit" className="submit-btn">
              Login
            </Button>
          </div>
        </Form>
      </div>
    </div>
  
    <style jsx>{`
      .login-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        background: linear-gradient(45deg, #6a11cb, #2575fc);
      }
  
      .login-box {
        background: white;
        border-radius: 12px;
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
        padding: 40px 30px;
        width: 100%;
        max-width: 400px;
        transition: all 0.3s ease;
        transform: scale(1);
        animation: fadeInUp 0.6s ease;
      }
  
      .login-header h1 {
        color: #6a11cb;
        font-size: 32px;
        text-align: center;
        margin-bottom: 15px;
      }
  
      .login-header p {
        font-size: 16px;
        text-align: center;
        color: #555;
      }
  
      .input-field {
        border-radius: 8px;
        padding: 12px;
        font-size: 14px;
        border: 1px solid #ddd;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
        margin-bottom: 20px;
        width: 100%;
        transition: all 0.3s ease;
      }
  
      .input-field:focus {
        border-color: #6a11cb;
        box-shadow: 0px 0px 15px rgba(106, 17, 203, 0.3);
      }
  
      .submit-btn {
        width: 100%; /* Make it responsive */
        background: linear-gradient(90deg, #6a11cb, #2575fc); /* Smooth gradient */
        color: white; /* Ensure text stands out */
        border: none; /* Remove border */
        padding: 12px 20px; /* Add padding for spacing */
        font-size: 16px; /* Comfortable font size */
        font-weight: bold; /* Bold for emphasis */
        border-radius: 12px; /* Slightly more rounded for a modern look */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
        display: flex; /* Use flexbox to center the content */
        justify-content: center; /* Horizontally center the text */
        align-items: center; /* Vertically center the text */
        text-align: center; /* Ensure the text is centered if it's multiline */
      }

      /* Add a hover effect */
      .submit-btn:hover {
        background: linear-gradient(90deg, #2575fc, #6a11cb); /* Reverse gradient on hover */
        transform: scale(1.05); /* Slightly enlarge on hover */
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Enhance shadow on hover */
      }

      /* Add an active state */
      .submit-btn:active {
        transform: scale(0.98); /* Slightly shrink on click */
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Reduced shadow on click */
      }
  
      .submit-btn:hover {
        background-color: #2575fc;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
      }
  
      .footer {
        text-align: center;
        margin-top: 20px;
      }
  
      @keyframes fadeInUp {
        from {
          opacity: 0;
          transform: translateY(50px);
        }
        to {
          opacity: 1;
          transform: translateY(0);
        }
      }
  
      /* Responsive Design */
      @media (max-width: 768px) {
        .login-box {
          max-width: 90%;
          padding: 30px 20px;
        }
  
        .login-header h1 {
          font-size: 28px;
        }
  
        .input-field {
          font-size: 12px;
          padding: 10px;
        }
  
        .submit-btn {
          font-size: 14px;
        }
      }
  
      @media (max-width: 480px) {
        .login-box {
          padding: 20px;
          max-width: 85%;
        }
  
        .login-header h1 {
          font-size: 24px;
        }
  
        .input-field {
          font-size: 10px;
          padding: 8px;
        }
  
        .submit-btn {
          font-size: 12px;
          padding: 10px;
        }
      }
    `}</style>
  </>
  
  );
};

export default Login;