import React, { useState, useEffect } from "react";
import { Modal, Button, Table, Form, Input, Select, message } from "antd";

import axios from "axios";
import DefaultLayout from '../components/DefaultLayout';
import CloseButton from "../components/CloseButton";

import {
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";

const MemberSetting = () => {

  const [membersData, setMembersData] = useState([]);
  const [popupModal, setPopupModal] = useState(false);
  const [editMember, setEditMember] = useState(null);
  const getAllMember = async () => {
    try {
      const { data } = await axios.get("/api/members/get-member");
      setMembersData(data.reverse()); 
    } catch (error) {
      console.log(error);
    }
  };
  
  useEffect(() => {
    getAllMember();
  }, []);


  //handle delete
  const handleDelete = async (record) => {
    try {


      await axios.post("/api/members/delete-member", { memberId: record._id });
      message.success('Member Deleted Successfully')
      getAllMember();
      setPopupModal(false);

    } catch (error) {
      message.success('Something Went Wrong')
      console.log(error);
    }
  }

  //able data
  const columns = [

    {
      title: 'No',
      key: 'index',
      render: (text, record, recordIndex) => (
        <span>{recordIndex + 1}</span>
      ),
      
    },
    { title: "Member Username", dataIndex: "username" },
 
    { title: "Member Phone", dataIndex: "phone" },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => (status ? "Available" : "Unavailable"),
      filters: [
        {
          text: "Available",
          value: true,
        },
        {
          text: "Not Available",
          value: false,
        },
      ],
      onFilter: (value, record) => record.status === value,
    },
    { title: "User ID", dataIndex: "uid" },


    {
      title: "Actions",
      dataIndex: "_id",
      render: (id, record) => (
        <div>
          <EditOutlined
            style={{
              cursor: "pointer"
            }}
            onClick={() => {
              setEditMember(record);
              setPopupModal(true);
            }} />
          <DeleteOutlined
            style={{ cursor: "pointer" }}
            onClick={() => { handleDelete(record) }}
          />

        </div>
      ),

    },
  ];

  //handle submit
  const handleSubmit = async (value) => {
    if (editMember === null) {
      try {

        const dateTime = new Date();
        const createdAt = dateTime.toLocaleString();
        const newValues = { ...value, createdAt };
        const res = await axios.post("/api/members/add-member", newValues);
        message.success('Member Added Successfully')
        getAllMember();
        setPopupModal(false);
      } catch (error) {
        message.success('Something Went Wrong')
        console.log(error);
      }
    } else {
      try {
        await axios.put("/api/members/edit-member", { ...value, memberId: editMember._id });
        message.success('Member Updated Successfully')
        getAllMember();
        setPopupModal(false);
      } catch (error) {
        message.success('Something Went Wrong')
        console.log(error);
      }
    }
  };

  return (
    <DefaultLayout>
      <div className="d-flex justify-content-between">
        <h1>Member List</h1>
        <Button type="primary" onClick={() => setPopupModal(true)}>Add Member</Button>
      </div>

      <Table columns={columns} dataSource={membersData} bordered />


      {
        popupModal && (
          <Modal 
            // title={`${editMember !== null ? 'Edit Member' : 'Add New Member'}`}
            title={
              <div>
                  {editMember !== null ? 'Edit Member' : 'Add New Member'}
                  <CloseButton onClick={() => { setEditMember(null); setPopupModal(false); }} />
              </div>
            }
            open={popupModal}
            onCancel={() => {
              setEditMember(null);
              setPopupModal(false);
            }}
            footer={false}>
            <Form layout="vertical" initialValues={editMember} onFinish={handleSubmit}>
              <Form.Item name="username" label="Username">
                <Input />
              </Form.Item>

          
              <Form.Item name="phone" label="Phone Number">
                <Input />
              </Form.Item>
              <Form.Item name="status" label="Availability">
                <Select>
                  <Select.Option value={true}>Available</Select.Option>
                  <Select.Option value={false}>Not Available</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="uid" label="User ID">
                <Input />
              </Form.Item>



              <div className="d-flex justify-content-end">
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </div>

            </Form>
          </Modal>
        )
      }

    </DefaultLayout>
  );



};

export default MemberSetting;